import PendingIgnoreComponent from "./PendingIgnoreComponent";

const PendingIgnoreModal = ({ ignoredResource, customerId, onClose, message = undefined }
    : any) => {
        return (
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
            <div className="flex flex-col bg-white shadow-md rounded-lg p-4 text-gray-800">
              <PendingIgnoreComponent ignoredResource={ignoredResource} customerId={customerId} message={message} onClose={onClose} requireConfirmation={false}/>
            </div>
          </div>
        )
}

export default PendingIgnoreModal;