import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { clearCurrentReport, clearEditedSections, setCurrentCustomerId, setCurrentReportId, setCurrentReportType, setPreview } from "../redux/dataSlice";

const TopNavBar = () => {
  const userMode = useAppSelector((state) => state.user.userMode);
  const sherlockId = useAppSelector((state) => state.user.sherlockId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pathName = window.location.pathname;

  const currentCustomerId = useAppSelector((state) => state.data.currentCustomerId);
  const isCustomised = useAppSelector((state) => state.data.isCustomised);
  const customisedReportCustomerId = useAppSelector( (state) => state.customiseReport.customisedReportCustomerId);
  const customisedReportId = useAppSelector((state) => state.customiseReport.customisedReportId);

  return (
    <div className="w-[90%] flex justify-between items-center h-8 py-8 border-b border-b-white">
      {["Reports", "Report Editor", "Customers", "Sherlock", "Sherlock Admin", "Diagnostics"].map((item, index) => {
        const showButton =
          item === "Reports" ||
          (item === "Report Editor" && userMode === "editor") ||
          (item === "Customers" && userMode === "editor") ||
          (item === "Sherlock" && sherlockId !== "invalid") ||
          (item === "Sherlock Admin" && userMode === "editor") ||
          (item === "Diagnostics" && userMode === "editor");

        // Map URLs to button names
        const urls = {
          "Reports": "/reportView",
          "Report Editor": "/reportEditor",
          "Customers": "/customers",
          "Sherlock": "/sherlock",
          "Sherlock Admin": "/sherlockAdmin",
          "Diagnostics": "/diagnostics",
        };

        return showButton ? (
          <button
            key={index}
            className={`${pathName.includes("/sherlockAdmin") && item === "Sherlock" ? "text-white" : pathName.includes(urls[item]) ? "text-ui-lime" : "text-white"} hover:text-ui-neonGreen focus:text-ui-neonGreen rounded transition duration-300 ease-in-out flex-grow text-center`}
            disabled={false}
            onClick={() => {
              dispatch(clearCurrentReport());
              if (item === "Report Editor") {
                if (isCustomised) {
                  if (customisedReportCustomerId !== "" && currentCustomerId !== customisedReportCustomerId) {
                    dispatch(setCurrentCustomerId(customisedReportCustomerId));
                  }
                  dispatch(setCurrentReportType("ice_customised_report"));
                  dispatch(setCurrentReportId({ id: customisedReportId, type: "editor" }));
                } else {
                  dispatch(clearEditedSections());
                  dispatch(setPreview(false));
                }
              }
              
              navigate(urls[item]);
            }}
          >
            {item}
          </button>
        ) : null;
      })}
    </div>
  );
};

export default TopNavBar;
