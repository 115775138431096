import { CustomiseReportPageContent, DynamicSizedPageContentTypes } from "../../models/ReportModel";
import { useAppSelector } from "../../redux/hook";
import { getFromPath } from "../../service/dataLogic";
import CustomisedPageContent from "./CustomisedPageContent";

const CustomisedReportContainer = ({ isRenderPage = false }) => {
  const currentReport = useAppSelector((state) => state.data.currentReport);

  if (currentReport === null)
    return <div className="text-white">Invalid Report</div>;

  const contents = getFromPath(currentReport, "content.pages") as unknown as CustomiseReportPageContent[];

  return (
    <div id="ReportContainer" className="w-full flex flex-col gap-2">
      {contents.map((c) => {
        const height = DynamicSizedPageContentTypes.includes(c.contentType)
          ? "h-fit"
          : isRenderPage
          ? "h-[100vh] max-h-[1024px]"
          : "h-[80vh] max-h-[1024px]";

        const padding = c.contentType === "iceImhrMonthly" || c.contentType === "reportTitle" ? "" : "p-2";

        return (
          <div id={c.contentId} key={c.contentId} className={`report_page break-before-all break-inside-avoid w-full ${height}`}>
            <CustomisedPageContent content={c} size={`w-full h-full ${padding}`} />
          </div>
        );
      })}
    </div>
  );
};

export default CustomisedReportContainer;