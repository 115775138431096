import { useAppSelector } from "../../redux/hook";
import { Baseline, CommonBaselineEntry, DataConnectorEntry } from "../../models/BaselineModel";
import { MdOutlineCheckCircleOutline, MdOutlineHighlightOff } from "react-icons/md";
import { EditorPageStyle } from "../../models/StyleModel";
import Theme from "../../Theme";
import { formatDateUTC } from "../../utility/DateHelpers";

const BaselineDisplay = () => {
  const baseline = useAppSelector((state) => state.data.currentAcceptedBaseline);
  if (!baseline || Object.entries(baseline).length === 0) return null;

  let entryStyle = `w-full p-1 my-2 text-white font-light text-sm rounded-xl shadow-sm ${Theme.Colors.DarkWebBg}`;
  let titleStyle = "text-base font-medium text-center flex flex-col my-3";

  const renderHeader = (title: string, hasContent: boolean) => (
    <div className={`${hasContent ? "mb-4" : "mb-1"}`}>
      <div className={titleStyle}>{title}</div>
      {!hasContent && <div className="text-center">No data available</div>}
    </div>
  );

  const renderDataConnectors = () =>
    baseline?.["Data Connectors"].map(
      (dataConnector: DataConnectorEntry, index) => {
        let bgColor = `${Theme.Colors.Primary}`;
        let statusIcon = (
          <MdOutlineCheckCircleOutline className="h-5 w-5 text-white" />
        );

        return (
          <div className="mx-2" key={index}>
            <div className="w-full">
              <div className={`${bgColor} border border-slate-50 text-sm flex flex-row`}>
                {statusIcon} {dataConnector.name}
              </div>
            </div>
            {Object.keys(dataConnector.types).length > 0 && (
              <div className="pl-8">
                {Object.keys(dataConnector.types).map((type, index) => {
                  if (!dataConnector.types[type].enabled) {
                    bgColor = Theme.Colors.Error;
                    statusIcon = (<MdOutlineHighlightOff className="h-5 w-5 text-white" />);
                  } else {
                    bgColor = `${Theme.Colors.Primary}`;
                  }

                  return (
                    <div key={index} className="w-full">
                      <div className={`${bgColor} border border-slate-50 text-sm flex flex-row justify-between items-center`}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "70%",
                          }}
                        >
                          <div className="mr-2">{statusIcon}</div>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {type}
                          </div>
                        </div>
                        <div>
                          {formatDateUTC(dataConnector.types[type].latest_active_data?.Time)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      }
    );

  const hasDataConnectors = !baseline ? false : baseline["Data Connectors"].length > 0;
  const hasAlertRules = !baseline ? false : baseline["Alert Rules"].length > 0;
  const hasAutomationRules = !baseline ? false : baseline["Automation Rules"].length > 0;
  const hasLogicApps = !baseline ? false : baseline["Logic Apps"].length > 0;
  const hasWatchlists = !baseline ? false : baseline["Watch Lists"].length > 0;
  const hasWorkbooks = !baseline ? false : baseline.Workbooks.length > 0;
  const hasHuntingQueries = !baseline ? false : baseline["Hunting Queries"].length > 0;

  const resourcesObj = {
    // CommonBaselineEntry resources
    "Logic Apps": hasLogicApps,
    "Workbooks": hasWorkbooks,
    "Automation Rules": hasAutomationRules,
    "Watch Lists": hasWatchlists,
    "Alert Rules": hasAlertRules,
    "Hunting Queries": hasHuntingQueries,
    // DataConnectorEntry resources - should be last
    "Data Connectors": hasDataConnectors,
  };

  const renderBaselineComponent = (baseline: Baseline | null) => {
    return (
      <div className={EditorPageStyle}>
        <div className="text-base font-medium text-center text-white">
          <div className={titleStyle}>CURRENT ENVIRONMENT</div>
          <div className={titleStyle}>Sentinel Baseline for {baseline?.date}</div>
          <div className="flex justify-center text-sm mb-3">Backup file: {baseline?.backup_container_name}</div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-row gap-2 text-sm text-white">
            <div>Legend:</div>
            <div className={`${Theme.Colors.Primary} flex flex-row`}>
              <MdOutlineCheckCircleOutline className="h-5 w-5" />: Enabled
            </div>
            <div className={`${Theme.Colors.Error} flex flex-row`}>
              <MdOutlineHighlightOff className="h-5 w-5" />: Disabled
            </div>
          </div>
        </div>
        {Object.keys(resourcesObj).map((resource, index) => {
          const hasResource =
            resourcesObj[resource as keyof typeof resourcesObj];

          if (resource === "Data Connectors") {
            return (
              <div key={index} className={entryStyle}>
                {renderHeader(resource, hasResource)}
                {renderDataConnectors()}
              </div>
            );
          }

          return (
            <div key={index} className={entryStyle}>
              {renderHeader(resource, hasResource)}
              {(
                baseline?.[resource as keyof Baseline] as CommonBaselineEntry[]
              )?.map((resourceEntry: CommonBaselineEntry, index: number) => {
                let bgColor = `${Theme.Colors.Primary}`;
                let statusIcon = (<MdOutlineCheckCircleOutline className="h-5 w-5 text-white" />);
                if (!resourceEntry.enabled) {
                  bgColor = Theme.Colors.Error;
                  statusIcon = (<MdOutlineHighlightOff className="h-5 w-5 text-white" />);
                }

                return (
                  <div key={index}>
                    <div className={`${bgColor} mx-2 border border-slate-50 text-sm flex flex-row`}>
                      {statusIcon} {resourceEntry.name}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-[90%] max-w-screen-2xl mt-5 mb-5 shadow-2xl">
      {renderBaselineComponent(baseline)}
    </div>
  );
};

export default BaselineDisplay;
