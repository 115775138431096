import { EditorStyle } from "../../models/EditorModel";
import { EditorPageStyle } from "../../models/StyleModel";
import EntryRow from "./EntryRow";

/**
 * Create an editor to edit dictionay in current report specified with 'path'. 
 * Dictionary can ONLY be one layer deep. (e.i. dict={a:2, b:5, c:"text here"})
 */

interface SingleEditorProp {
    editorTitle: string;
    path: string
}

function SingleEditor({editorTitle, path} : SingleEditorProp) {

    let style = EditorStyle.STR_INPUT
    if (path.includes("summary") || path.includes("best_practice")) {
        style = EditorStyle.MULTI_LINE_STR
    }
    
    return (
        <div className={EditorPageStyle}>
            <h1 className="font-medium text-white text-xl mx-2 my-3">{editorTitle}</h1>
            <EntryRow path={path} style={style}/>
        </div>
    )
}

export default SingleEditor