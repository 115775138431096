import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { setupStore } from "./redux/store";
import {msalConfig} from "./msalConfig";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { setAccountSet } from "./redux/userSlice";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance?.getAllAccounts();
if (accounts && accounts.length > 0 ) {
	msalInstance?.setActiveAccount(accounts[0]);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const store = setupStore()

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

msalInstance.addEventCallback((event: EventMessage) => {
	if ((msalInstance?.getAllAccounts().length > 0) && event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const account = payload.account;
		msalInstance?.setActiveAccount(account);
    store.dispatch(setAccountSet({accountSet: true}));
	}
});

if (window.location.hash !== "") {
  console.log(`hash found: ${window.location.hash}`);
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App msalInstance={msalInstance}/>
          {/* TODO remove this Devtools in production or add a node env */}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
