import html2canvas from "html2canvas";
import ReportStatus from "../components/utility/ReportStatus";
import { IMReports, ReportType, ReportTypes } from "../models/ReportModel";
import { PageType } from "../redux/dataSlice";
import jsPDF from "jspdf";
import { BaselineObjects, HistoricalBaselineIds } from "../models/BaselineModel";
import { CustomerInfo } from "../models/CustomerModel";
import { TrendingFrequency, TrendingPagedDataModel } from "../models/TrendingModel";

export function getSyncTimeString() {
    let currentdate = new Date();
    let datetime = "Synced: " + currentdate.getDate() + "/"
                    + (currentdate.getMonth()+1) + "/" 
                    + currentdate.getFullYear() + " "
                    + currentdate.getHours() + ":"
                    + currentdate.getMinutes()
    return datetime
}

const parseCustomerName = (name: string) => {
    if (name.length === 0) return
    if (name.includes("_")) {
        const words = name.split("_")
        return words.map((word) => {
            if (word.toLowerCase() === "nz") {
                return "NZ "
            }
            return word.charAt(0).toUpperCase() + word.slice(1) + " "
        })
    } else {
        return name.charAt(0).toUpperCase() + name.slice(1)
    }
}

export function generateAllCustomerOption(customerIDs: string[]) {
    const allCustomerIds = [...customerIDs];
    allCustomerIds.sort((a, b) => a.localeCompare(b));
    let options = []
    for (let id of allCustomerIds) {
        options.push(<option key={id} value={id}>{parseCustomerName(id)}</option>)
    }
    return options
}

export function generateCustomerOption(obj: IMReports | BaselineObjects) {
    let customerIds = [...Object.keys(obj)];
    customerIds.sort((a, b) => a.localeCompare(b));
    let options = []
    for (let customerId of customerIds) {
        options.push(<option key={customerId} value={customerId}>{parseCustomerName(customerId)}</option>)
    }
    return options
}

export function generateWorkspaceOptions(customer: CustomerInfo, baselines: BaselineObjects) {
    let options = []
    for (let config of customer.sentinel_configs) {
        if (!baselines?.[customer.customer_id]?.[config.workspace]) continue
        options.push(<option key={config.workspace} value={config.workspace}>{config.workspace}</option>)
    }
    return options
}

export function generateTypeOption(reports: IMReports, currentCustomerId: string) {
    let options = []
    if (currentCustomerId === "") return
    if (!reports[currentCustomerId]) return 

    for (let reportType in reports[currentCustomerId]) {
        options.push(<option key={reportType} value={reportType}>{reportType}</option>)
    }
    return options
}

export function generateReportOption(reports: IMReports, currentCustomerId: string, currentReportType: ReportType) {
    let options = []
    if (currentCustomerId === "") return
    if (!ReportTypes.includes(currentReportType)) return
    if (!reports[currentCustomerId] || !reports[currentCustomerId][currentReportType]) return
    
    for (let reportId in reports[currentCustomerId][currentReportType]) {
        options.push(<option key={reportId} value={reportId}>{reportId}</option>)
    }
    return options.sort((a, b) => {
        if (a.key === null || b.key === null) return 0
        if (a.key < b.key) return 1
        if (a.key > b.key) return -1
        return 0
    })
}

export function generateTrendingFrequencyOption() {
    let options = []
    const selectableOptions = ["month"]

    for (let frequency of selectableOptions) {
        options.push(<option key={frequency} value={frequency}>{frequency}</option>)
    }
    return options
}

export function generateTrendingPeriodOption(cachedData: TrendingPagedDataModel | undefined, frequency: TrendingFrequency) {
    let options = []
    const allData = cachedData?.pages?.flatMap((page) => page.data) || []

    const selectableOptions = [3, 6, 9, 12].filter((option) => option <= allData.length)
    for (let period of selectableOptions) {
       options.push(<option key={period} value={period}>Last {period} {frequency}s</option>) 
    }
    
    return options
}

export function generateHistoricalBaselineOptions(historicalBaselineIds: HistoricalBaselineIds | null) {
    let options = []
    if (!historicalBaselineIds ) return;
    for (let baselineId of historicalBaselineIds) {
        const index = baselineId.lastIndexOf("_")
        options.push(<option key={baselineId} value={baselineId}>{baselineId.substring(index+1)}</option>)
    }
    return options
}

export function generateBaselineStatus(lastSyncTime: string) {
    const time = lastSyncTime.split(": ")
    return (
        <div>
            <h2 className="font-light text-sm ml-1 text-lime-100">{time[0] + ": "}</h2>
            <h2 className="font-light text-sm ml-1 text-lime-100">{time[1]}</h2>
        </div>
    )
}

export function generateStatus(reports: IMReports, currentCustomerId: string, lastSyncTime: string, pageType: PageType) {
    const time = lastSyncTime.split(": ")

    if (currentCustomerId === "") {
        return (
        <div>
            <h2 className="font-light text-sm ml-1 text-lime-100">{time[0] + ": "}</h2>
            <h2 className="font-light text-sm ml-1 text-lime-100">{time[1]}</h2>
            <hr className="border-ui-cyan-1 my-0.5"></hr>
            {pageType === "editor" ?
                <ReportStatus isReady={true} text={"Editor Ready"} />
                :
                <ReportStatus isReady={true} text={"Select Report"} />
            }
        </div>)
    } else {
        let statusList = [
            <h2 key={0} className="font-light text-sm ml-1 text-lime-100">{time[0] + ": "}</h2>,
            <h2 key={1} className="font-light text-sm ml-1 text-lime-100">{time[1]}</h2>,
            <hr key={2} className="border-ui-cyan-1 my-0.5"></hr>
        ]

        if (!reports[currentCustomerId]) return
        let componentKey = 2

        //Display imhr weekly status
        if (reports[currentCustomerId].hasOwnProperty("ice_imhr_week")) {
            const imhrWeekReports = reports[currentCustomerId]["ice_imhr_week"]
            if (imhrWeekReports === undefined) return
            
            ++componentKey
            statusList.push(<hr key={componentKey} className="border-ui-cyan-1 my-0.5"></hr>)
            ++componentKey

            if (Object.keys(imhrWeekReports).length === 0) {
                statusList.push(<ReportStatus key={componentKey} isReady={false} text={"No IM Weekly"} />)
            } else if (pageType === "editor") {            
                statusList.push(<ReportStatus key={componentKey} isReady={false} text={"Check IM Weekly"} />)
            } else if (pageType === "view") {
                statusList.push(<ReportStatus key={componentKey} isReady={true} text={"IM Weekly"} />)
            }
        }

        //Display imhr monthly status
        if (reports[currentCustomerId].hasOwnProperty("ice_imhr_month")) {
            const imhrMonthReports = reports[currentCustomerId]["ice_imhr_month"]
            if (imhrMonthReports === undefined) return 

            ++componentKey
            statusList.push(<hr key={componentKey} className="border-ui-cyan-1 my-0.5"></hr>)
            ++componentKey
            
            if (Object.keys(imhrMonthReports).length === 0) {
                statusList.push(<ReportStatus key={componentKey} isReady={false} text={"No IM Monthly"} />)
            } else if (pageType === "editor") {
                statusList.push(<ReportStatus key={componentKey} isReady={false} text={"Check IM Monthly"} />)
            } else if (pageType === "view") {
                statusList.push(<ReportStatus key={componentKey} isReady={true} text={"IM Monthly"} />)
            }
        }

        //Display secure connect status
        if (reports[currentCustomerId].hasOwnProperty("secure_connect_month")) {
            const scReports = reports[currentCustomerId]["secure_connect_month"] 
            if (scReports === undefined) return 

            ++componentKey
            statusList.push(<hr key={componentKey} className="border-ui-cyan-1 my-0.5"></hr>)
            ++componentKey 

            if (Object.keys(scReports).length === 0) {
                statusList.push(<ReportStatus key={componentKey} isReady={false} text={"No SC Monthly"} />)
            } else if (pageType === "editor") {
                statusList.push(<ReportStatus key={componentKey} isReady={false} text={"Check SC Monthly"} />)
            } else if (pageType === "view") {
                statusList.push(<ReportStatus key={componentKey} isReady={true} text={"SC Monthly"} />)
            }
        }

        //Display Sherlock weekly status
        if (pageType === "view" && reports[currentCustomerId].hasOwnProperty("sherlock_report_week")) {
            const sherlockWeeklyReports = reports[currentCustomerId]["sherlock_report_week"] 
            if (sherlockWeeklyReports !== undefined && Object.keys(sherlockWeeklyReports).length > 0) {
                ++componentKey
                statusList.push(<hr key={componentKey} className="border-ui-cyan-1 my-0.5"></hr>)
                ++componentKey

                statusList.push(<ReportStatus key={componentKey} isReady={true} text={"Sherlock Weekly"} />)
            }
        }

        //Display Sherlock monthly status
        if (pageType === "view" && reports[currentCustomerId].hasOwnProperty("sherlock_report_month")) {
            const sherlockMonthlyReports = reports[currentCustomerId]["sherlock_report_month"] 
            if (sherlockMonthlyReports !== undefined && Object.keys(sherlockMonthlyReports).length > 0) {
                ++componentKey
                statusList.push(<hr key={componentKey} className="border-ui-cyan-1 my-0.5"></hr>)
                ++componentKey

                statusList.push(<ReportStatus key={componentKey} isReady={true} text={"Sherlock Monthly"} />)
            }
        }

        return statusList
    }
} 

export const exportToSinglePagePDF = (reportId: string, customerName: string) => { 
    const element = document.getElementById("ReportContainer")
    if (!element) return
    
    const fileName = parseReportFileName(reportId, customerName)
    html2canvas(element!, {
        scale: 2
    }).then((canvas) => { 
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: "a4"
        })

        const canvasWidth = canvas.width
        const canvasHeight = canvas.height

        const pageWidth = pdf.internal.pageSize.getWidth()
        const pageHeight = pdf.internal.pageSize.getHeight()

        // paint dark web colour as background
        pdf.setFillColor(18, 38, 31)
        pdf.rect(0, 0, pageWidth, pageHeight, "F")

        // caculate the best scale ratio
        const scaleX = pageWidth / canvasWidth
        const scaleY = pageHeight / canvasHeight
        
        // * 0.98 to leave some space from the edge
        const scale = Math.min(scaleX, scaleY) * 0.98

        // caculate the scaled sizes to fit the image into page
        const scaledWidth = canvasWidth * scale
        const scaledHeight = canvasHeight * scale
        
        // caculate the postion to center the image
        const startX = (pageWidth - scaledWidth) / 2
        const startY = (pageHeight - scaledHeight) / 2

        pdf.addImage(imgData,"PNG",startX,startY,scaledWidth,scaledHeight)
        pdf.save(`${fileName}.pdf`)
    })
}

export const exportToMultiPagePDF = async (reportId: string, customerName: string) => {
    const element = document.getElementById("ReportContainer")
    if (!element) return

    const fileName = parseReportFileName(reportId, customerName)
    const childDivs = element.querySelectorAll(":scope > div")
    const canvasArr = Array.from(childDivs).map((element) => {
        return html2canvas(element as HTMLElement, { scale:2 })
    })

    const canvases = await Promise.all(canvasArr)

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4"
    })

    const pageCount = childDivs.length

    for (let i = 0; i < pageCount; i++) {
        const imgData = canvases[i].toDataURL("image/png")

        const canvasWidth = canvases[i].width
        const canvasHeight = canvases[i].height

        const pageWidth = pdf.internal.pageSize.getWidth()
        const pageHeight = pdf.internal.pageSize.getHeight() 
        
        const scaleX = pageWidth / canvasWidth
        const scaleY = pageHeight / canvasHeight
        
        const scale = Math.min(scaleX, scaleY)

        const scaledWidth = canvasWidth * scale
        const scaledHeight = canvasHeight * scale
        
        const startX = (pageWidth - scaledWidth) / 2
        const startY = (pageHeight - scaledHeight) / 2

        pdf.addImage(imgData,"PNG",startX,startY,scaledWidth,scaledHeight)

        if (i < pageCount - 1) {
            pdf.addPage("a4","portrait")
        }
    }

    pdf.save(`${fileName}.pdf`)
}

export const parseReportFileName = (reportId: string, customerName: string) => {
    let fileName = customerName + " - "
    if (reportId.includes("ice_imhr_week")) {
        fileName += "DEFEND iCE Weekly Summary Report" + reportId.slice("ice_imhr_week".length)
    } else if (reportId.includes("ice_imhr_month")) {
        fileName += "DEFEND iCE Monthly Summary Report" + reportId.slice("ice_imhr_month".length)
    } else if (reportId.includes("sherlock_report_week")) {
        fileName += "DEFEND Sherlock Weekly Summary Report" + reportId.slice("sherlock_report_week".length)
    } else if (reportId.includes("sherlock_report_month")) {
        fileName += "DEFEND Sherlock Monthly Summary Report" + reportId.slice("sherlock_report_month".length)
    } else if (reportId.includes("secure_connect_month")) {
        fileName += "DEFEND Secure Connect Monthly Summary Report" + reportId.slice("secure_connect_month".length)
    } else if (reportId.includes("ice_customised_report")) {
        fileName += "DEFEND iCE Trending Report" + reportId.slice("ice_customised_report".length)
    }

    return fileName
}