import { ResponsiveLine } from "@nivo/line";
import { LineChartData } from "../report/LineChart";

const CountLineChart = ({ data }: { data: LineChartData[] }) => {
    const ticketValues = data[0].data
        .filter((_, index) => index % 2 === 0)
        .map((data) => data.x)

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 20, right: 50, bottom: 50, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: ticketValues,
                legend: "date",
                legendOffset: 36,
                legendPosition: "middle"
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "sightings count",
                legendOffset: -60,
                legendPosition: "middle"
            }}
            colors={{ scheme: "accent" }}
            curve="linear"
            enablePoints={true}
            enablePointLabel={false}
            pointLabel={"yFormatted"}
            enableGridX={true}
            enableGridY={true}
            enableArea={false}
            areaOpacity={0.2}
            areaBlendMode="normal"
            areaBaselineValue={0}
            fill={[]}
            defs={[]} 
            role="sightingsCountLineChart"
            lineWidth={2}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            layers={["grid", "markers", "axes", "areas", "crosshair", "lines", "points", "slices", "mesh", "legends"]}
            isInteractive={true}
            useMesh={true}
            debugMesh={false}
            enableSlices={false}
            debugSlices={false}
            enableCrosshair={false}
            crosshairType="bottom-left"
            tooltip={() => null}
            sliceTooltip={() => null}
            legends={[]}
        />
    )
}

export default CountLineChart