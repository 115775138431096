import { setSherlockSubId, setTeamsWebhookSherlock } from "../../../redux/formDataSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import FormTitle from "../utilities/FormTitle";
import FormInput from "../utilities/FormInput";
import { setFormInvalid, setFormValid } from "../../../redux/formSlice";
import { useEffect } from "react";

const FormSherlock = () => {
  const dispatch = useAppDispatch();
  const currentFormData = useAppSelector((state) => state.formData);
  const isFieldValidated = currentFormData.sherlock_sub_id !== "";

  useEffect(() => {
    if (isFieldValidated) dispatch(setFormValid());
    else dispatch(setFormInvalid());
  }, [isFieldValidated]);

  return (
    <div>
      <FormTitle />
      <FormInput
        title="Sherlock Subscription ID"
        name="sherlock-sub-id"
        extraStyle="font-bold"
        value={currentFormData.sherlock_sub_id}
        onChangeAction={(event) => dispatch(setSherlockSubId(event.target.value))}
      />
      <FormInput
        title="Teams Webhook (Optional)"
        name="team-webhook"
        extraStyle="font-bold"
        required={false}
        value={currentFormData.teams_webhook_sherlock}
        placeholder="The Customer MS Team Webhook to use as comms channel for notification..."
        onChangeAction={(event) =>
          dispatch(setTeamsWebhookSherlock(event.target.value))
        }
      />
    </div>
  );
};

export { FormSherlock };
