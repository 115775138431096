import { BarChartContentData, CustomisedTitleBackground, CustomiseReportPageContent, LineChartContentData, ReportObject, TableChartContentData, TitleContentData } from "../../models/ReportModel";
import { ReportCardTileStyle } from "../../models/StyleModel";
import Theme from "../../Theme";
import TrendingBarChart from "../trendingBoard/TrendingBarChart";
import TrendingLineChart from "../trendingBoard/TrendingLineChart";
import TrendingTable from "../trendingBoard/TrendingTable";
import IMMonthlyReport from "./IMMonthlyReport";
import DefendLogoWhite from "../../resource/DEFENDLogo-White.png";
import DefendLogoBlack from "../../resource/DEFENDLogo-Black.png";


const CustomisedPageContent = ({ content, size }: { content: CustomiseReportPageContent, size: string }) => {
    const contentType = content.contentType
    let title = ""
    let chartWidth = "w-full"
    let summaryWidth = ""

    if (["trendingBar", "trendingLine", "trendingTable"].includes(contentType)) {
        const contentDataToType = content.contentData as BarChartContentData | LineChartContentData | TableChartContentData
        chartWidth = contentDataToType.summmaryRequired ? "w-[80%]" : "w-full"
        summaryWidth = contentDataToType.summmaryRequired ? "w-[20%]" : ""
    }

    function renderSummary(required: boolean, summary: string) {
        if (!required) return null
        return <span className={`${summaryWidth} mx-2 text-sm whitespace-pre-wrap`}>{summary}</span>
    }

    function renderPageChart() {
        switch (contentType) {
            case "reportTitle":
                const titleContent = content.contentData as TitleContentData
                const reportTitle = titleContent.title
                const subTitle = titleContent.subTitle
                const author = titleContent.author
                const logoRequired = titleContent.logoRequired
                const background = titleContent.background
                const bgImgUrl = CustomisedTitleBackground[background as keyof typeof CustomisedTitleBackground]
                return (
                    <div className={`w-full h-full flex flex-col justify-between ${background === "White" ? "" : "bg-cover"}`} style={ background === "White" ? undefined : { backgroundImage: `url(${bgImgUrl})`}} >
                        <div className="ml-2 mt-2">
                            {logoRequired 
                                ? <img src={background === "White" ? DefendLogoBlack : DefendLogoWhite} className="w-16 h-16" alt="Defend logo" />
                                : null
                            }
                        </div>

                        <div className="flex flex-col ml-44">
                            <span className={`w-[70%] ${background === "White" ? "text-neutral-900" : "text-white"} text-4xl font-bold whitespace-pre-wrap`}>{reportTitle}</span>
                            <span className={`w-[60%] ${background === "White" ? "text-neutral-900" : "text-white"} text-xl font-medium whitespace-pre-wrap`}>{subTitle}</span>
                        </div>

                        <span className={`w-[50%] ${background === "White" ? "text-neutral-900" : "text-white"} text-xs ml-2 mb-2`}>{author}</span>
                    </div>
                )
                
            case "iceImhrMonthly":
                const reportData = content.contentData as ReportObject
                return (
                    <div className="w-full h-full">
                        <IMMonthlyReport reportData={reportData} />
                    </div>
                )
        
            case "trendingBar":
                const barData = content.contentData as BarChartContentData
                title = (barData.category as string).split("_").join(" ").toUpperCase()

                return (
                    <div className="w-full h-full">
                        <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>{title}</h1>
                        <div className="w-full h-full flex flex-row">
                            <div className={`${chartWidth} h-[94%]`}>
                                <TrendingBarChart data={barData.chartData} category={barData.category} dataKeys={barData.dataKeys} avgData={barData.avgData}  />
                            </div>
                            
                            {renderSummary(barData.summmaryRequired, barData.summary)}
                        </div>
                    </div>
                )

            case "trendingLine":
                const lineData = content.contentData as LineChartContentData
                title = (lineData.category as string).split("_").join(" ").toUpperCase()
                return (
                    <div className="w-full h-full">
                        <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>{title}</h1>
                        <div className="w-full h-full flex flex-row">
                            <div className={`${chartWidth} h-[94%]`}>
                                <TrendingLineChart data={lineData.chartData} />
                            </div>
                            
                            {renderSummary(lineData.summmaryRequired, lineData.summary)}
                        </div>
                    </div>
                )

            case "trendingTable":
                const tableData = content.contentData as TableChartContentData
                title = (tableData.category as string).split("_").join(" ").toUpperCase()
                return (
                    <div className="w-full h-full">
                        <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>{title}</h1>
                        <div className="w-full h-full flex flex-row">
                            <div className={chartWidth}>
                                <TrendingTable data={tableData.chartData} category={tableData.category} />
                            </div>
                            
                            {renderSummary(tableData.summmaryRequired, tableData.summary)}
                        </div>
                    </div>
                )
        }
    }

    return (
        <div className={`${size} bg-white`}>
            {renderPageChart()}
        </div>
    )
}

export default CustomisedPageContent