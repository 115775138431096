import Theme from "../Theme";
import MenuBar from "../components/MenuBar";
import TopNavBar from "../components/TopNavBar";
import HomeBackground from "../resource/DEFEND-Main-Home.jpg";

const Home = () => {
  return (
    <div className={`flex ${Theme.Colors.DarkWebBg}`}>
      <MenuBar onPage="home" />
      <div className="w-full flex flex-col items-center min-h-screen pl-44 bg-cover" style={{ backgroundImage: `url(${HomeBackground})` }}>
        <TopNavBar />
        <div className="w-[90%] h-1/4 mt-10 mb-10 shadow-2xl">
          <h1 className="text-white text-4xl font-medium pt-[20vh]">SATURN Portal</h1>
          <h1 className="text-white text-2xl py-6">Please use top menu to navigate</h1>
        </div>
      </div>
    </div>
  );
};

export default Home;