import FormInput from "./FormInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  addSentinelRecord,
  removeSentinelRecord,
  setSentinelResourceGroup,
  setSentinelSubscriptionId,
  setSentinelWorkspace,
  setSentinelWorkspaceId,
} from "../../../redux/formDataSlice";

const SentinelForm = () => {
  const dispatch = useAppDispatch();
  const currentFormData = useAppSelector((state) => state.formData);
  const currentSentinelRecords = currentFormData.sentinel_records;

  const handleRecordAdd = () => {
    dispatch(addSentinelRecord());
  };

  const handleRecordRemove = (index: number) => {
    dispatch(removeSentinelRecord(index));
  };

  const handleRecordUpdate = (e: any, index: number) => {
    switch (e.target.name) {
      case "workspace":
        dispatch(setSentinelWorkspace({ workspace: e.target.value, index }));
        break;
      case "workspaceId":
        dispatch(
          setSentinelWorkspaceId({ workspaceId: e.target.value, index })
        );
        break;
      case "resourceGroup":
        dispatch(
          setSentinelResourceGroup({ resourceGroup: e.target.value, index })
        );
        break;
      case "subscriptionId":
        dispatch(
          setSentinelSubscriptionId({ subscriptionId: e.target.value, index })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <label className="block text-gray-700 text-sm font-bold my-2">
        Sentinel Records
      </label>
      {currentSentinelRecords.map((singleRecord, index) => (
        <div key={index} className="record mb-3">
          <div className="sentinel-record-header flex items-center gap-4">
            <label className="block text-gray-700 text-sm font-medium my-2">
              Sentinel Record {index + 1}
            </label>
            {currentSentinelRecords.length > 1 && (
              <button
                type="button"
                className="removeBtn w-[85px] h-[24px] text-white text-sm rounded-lg border border-transparent bg-red-500 hover:bg-red-600"
                onClick={() => handleRecordRemove(index)}
              >
                - Remove
              </button>
            )}
          </div>
          <div className="sentinel-record-details">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <FormInput
                  title="Workspace"
                  name="workspace"
                  required={true}
                  extraStyle="text-sm"
                  value={currentSentinelRecords[index].workspace}
                  onChangeAction={(event) => handleRecordUpdate(event, index)}
                />
              </div>
              <div className="flex flex-col">
                <FormInput
                  title="Workspace ID"
                  name="workspaceId"
                  required={true}
                  value={currentSentinelRecords[index].workspace_id}
                  extraStyle="text-sm"
                  onChangeAction={(event) => handleRecordUpdate(event, index)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <FormInput
                  title="Resource Group"
                  name="resourceGroup"
                  required={true}
                  value={currentSentinelRecords[index].resource_group}
                  extraStyle="text-sm"
                  onChangeAction={(event) => handleRecordUpdate(event, index)}
                />
              </div>
              <div className="flex flex-col">
                <FormInput
                  title="Subscription ID"
                  name="subscriptionId"
                  required={true}
                  value={currentSentinelRecords[index].subscription_id}
                  extraStyle="text-sm"
                  onChangeAction={(event) => handleRecordUpdate(event, index)}
                />
              </div>
            </div>
            {currentSentinelRecords.length - 1 === index &&
              currentSentinelRecords.length < 3 && (
                <button
                  type="button"
                  className="addBtn border border-transparent rounded bg-indigo-600 hover:bg-indigo-700 text-white text-sm px-2 py-2 mt-2"
                  onClick={handleRecordAdd}
                >
                  + Add Sentinel Record
                </button>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SentinelForm;
