import { useState } from "react";
import { CountByDate, IncidentEntity, TRENDING_COLOR_SCHEME, TrendingChartConfig, TrendingCountAvgData, TrendingDataModel } from "../../models/TrendingModel";
import TrendingBarChart from "./TrendingBarChart";
import { BarDatum } from "@nivo/bar";
import { Serie } from "@nivo/line";
import TrendingLineChart from "./TrendingLineChart";
import { AlertsBySource, BarChartContentData, CustomiseReportPageContent, CustomiseReportPageContentType, LineChartContentData, TableChartContentData } from "../../models/ReportModel";
import Theme from "../../Theme";
import { ReportCardTileStyle } from "../../models/StyleModel";
import RoundButton from "../utility/RoundButton";
import TrendingTable from "./TrendingTable";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { setCurrentPageContent, setSelectedEntryId } from "../../redux/customiseReportSlice";

const TrendingChartContainer = ({ selectedData, config }: { selectedData: TrendingDataModel[], config: TrendingChartConfig }) => {
    const product = config.product;
    const category = config.category;
    const avgSource = config.avgSource;
    const [chartType, setChartType] = useState(config.chartTypes[0]);
    const [avgRequired, setAvgRequired] = useState(config.avgRequired);

    let barChartData: BarDatum[] = [];
    let lineChartData: Serie[] = [];
    let tableData: IncidentEntity[] = [];
    let dataKeys: string[] = [];
    let avgData: TrendingCountAvgData[] = [];

    // customised report info
    const customerId = useAppSelector((state) => state.data.currentCustomerId);
    const period = useAppSelector((state) => state.trending.period);
    const frequency = useAppSelector((state) => state.trending.frequency);
    const selectedEntryIds = useAppSelector((state) => state.customiseReport.selectedEntryIds);
    const selectedEntryId = useAppSelector((state) => state.customiseReport.selectedEntryId);
    const chartId = customerId + "_" + product + "_" + category + "_" + chartType + "_" + period + "_" + frequency;

    const dispatch = useAppDispatch()

    function getAvgData() {
        const avgData: TrendingCountAvgData[] = [];
        if (avgSource === undefined || !avgRequired) {
            return []
        }

        for (let key of dataKeys) {
            avgData.push({
                "chartData": [],
                "chartKey": key
            })
        }

        for (let trending of selectedData) {
            const productData = trending[product]
            if (productData) {
                const avgInTrending = productData[avgSource];

                for (let item of avgData) {
                    let count: number = 0
                    if (Object.keys(avgInTrending).includes(item.chartKey)) {
                        count = avgInTrending[item.chartKey]
                    }

                    const point: CountByDate = {
                        "date": trending.start_date,
                        "count": count
                    }
                    
                    item.chartData.unshift(point);
                }
            }
        }
        return avgData;
    }

    function getBarChartData() {
        const chartDataList = [];
        const dataKeys: string[] = [];

        for (let data of selectedData) {
            const targetData = data[product][category];
            if (targetData) {
                let chartData: any = {
                    "date": data.start_date,
                }
                Object.entries(targetData).forEach(([key, value]) => {
                    chartData[key] = value
                    if (!dataKeys.includes(key)) {
                        dataKeys.push(key)
                    }
                })
                chartDataList.unshift(chartData);
            }
        }
        return [chartDataList, dataKeys];
    }

    function getLineChartData() {
        const lineChartList = [];
        let chartData: any = {
            "id": category,
            "color": TRENDING_COLOR_SCHEME[category],
            "data": []
        }
        for (let data of selectedData) {
            const targetData = data[product][category]
            let pointData: any = {
                "x": data.start_date,
                "y": targetData
            }
            chartData["data"].unshift(pointData);
        }
        lineChartList.push(chartData);
        return lineChartList;
    }

    // now only work for ice_imhr alerts_by_source list
    function getBarChartDataFromList() {
        const chartDataList = [];
        const dataKeys: string[] = [];
        for (let data of selectedData) {
            let chartData: any = {
                "date": data.start_date,
            }
            const targetData = data[product][category] as unknown as AlertsBySource[];
            if (targetData.length > 0) {
                for (let dataInCategory of targetData) {
                    chartData[dataInCategory["source"]] = dataInCategory["count"]
                    if (!dataKeys.includes(dataInCategory["source"])) {
                        dataKeys.push(dataInCategory["source"])
                    }
                }
            }
            chartDataList.unshift(chartData);
        }
        return [chartDataList, dataKeys];
    }

    function chartButtons() {
        switch (chartType) {
            case "bar":
                switch (avgSource) {
                    case undefined:
                        return null;
                    default:
                        return (
                            <RoundButton
                                text= {avgRequired ? "Disable Avg" : "Show Avg"}
                                colour={avgRequired ? "bg-orange-500" : `${Theme.Colors.Primary}`}
                                extraStyle="w-32 h-6 m-2 pt-1"
                                onClickAction={() => {
                                    setAvgRequired(!avgRequired)
                                }}
                            />
                        );
                }

            default:
                return null;
        }
    }

    function getTopIncidentEntitiesTableData() {
        const tableData: IncidentEntity[] = [];
        for (let data of selectedData) {
            for (let entity of data.ice_imhr.incident_entities) {
                const kind = entity.kind;
                const friendlyName = entity.friendlyName;
                const count = entity.count;
                let populatedData = tableData.find((d) => d.kind === kind && d.friendlyName === friendlyName);
                if (populatedData) {
                    populatedData.count += count
                } else {
                    const newData: IncidentEntity = {
                        kind: kind,
                        friendlyName: friendlyName,
                        count: count
                    }
                    tableData.push(newData);
                }
            }

        }
        const top10 = tableData.sort((a, b) => b.count - a.count).slice(0, 10);

        return top10;
    }

    function generateCustomisedReportPageContent (): CustomiseReportPageContent {
        let data: BarChartContentData | LineChartContentData | TableChartContentData = {                    
            "chartData": [],
            "category": category,
            "summmaryRequired": false,
            "summary": "" 
        };
        let contentType: CustomiseReportPageContentType = "trendingBar";

        switch (chartType) {
            case "bar":
                data = {
                    "chartData": barChartData,
                    "dataKeys": dataKeys,
                    "category": category,
                    "avgData": avgData,
                    "summmaryRequired": false,
                    "summary": ""
                }
                contentType = "trendingBar"
                break;

            case "line":
                data = {
                    "chartData": lineChartData,
                    "category": category,
                    "summmaryRequired": false,
                    "summary": ""
                }
                contentType = "trendingLine"
                break;
            
            case "table":
                data = {
                    "chartData": tableData,
                    "category": category,
                    "summmaryRequired": false,
                    "summary": ""
                }
                contentType = "trendingTable"
                break;

            default:
                break;
        }

        return {
            "contentId": chartId,
            "contentType": contentType,
            "customerId": customerId,
            "contentData": data
        };
    }

    // update chartData for charts
    switch (chartType) {
        case "bar":
            switch (category) {
                case "alerts_by_source":
                    [barChartData, dataKeys] = getBarChartDataFromList()
                    break;
            
                default:
                    [barChartData, dataKeys] = getBarChartData()
                    break;
            }
            break;
        
        case "line":
            lineChartData = getLineChartData()
            break;
        case "table":
            tableData = getTopIncidentEntitiesTableData()
            break;
        default:
            break;
    }

    // update average data for average lines in chart
    if (avgRequired) {
        avgData = getAvgData();
    }

    // TODO update badgeColor when chart inserted into customised report
    let badgeColour = Theme.Colors.Primary;
    const title = (category as string).split("_").join(" ").toUpperCase();
    
    return (
        <div className={`w-[90%] mx-auto my-2 ${chartType === "table" ? "h-auto" : "h-[60vh]"} bg-white ${selectedEntryIds.includes(chartId) ? "border-2 border-yellow-500": ""} ${selectedEntryId === chartId ? "brightness-90" : ""} hover:brightness-90`} 
            onClick={() => {
                dispatch(setSelectedEntryId(chartId));
                dispatch(setCurrentPageContent(generateCustomisedReportPageContent()));
            }}
        >
            <div className="w-full flex flex-row justify-between">
                <h1 className={`${badgeColour} ${ReportCardTileStyle}`}>{title}</h1>
                {
                    chartButtons()
                }
            </div>

            {
                chartType === "bar" ?
                <TrendingBarChart data={barChartData} category={category} dataKeys={dataKeys} avgData={avgData} />
                :
                chartType === "line" ?
                <TrendingLineChart data={lineChartData as Serie[]} />
                :
                chartType === "table" ?
                <TrendingTable data={tableData} category={category} />
                :
                <div>Other chart type</div>
            }
        </div>
    )
}

export default TrendingChartContainer;