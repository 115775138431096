import { createSlice } from "@reduxjs/toolkit";
import { TrendingFrequency } from "../models/TrendingModel";

interface TrendingState {
    frequency: TrendingFrequency,
    period: number,
    pageSize: number
}

const initialState: TrendingState = {
    frequency: "month",
    period: 3,
    pageSize: 3
}

export const trendingSlice = createSlice({
    name: 'trending',
    initialState,
    reducers: {
        setTrendingFrequency: (state, action) => {
            state.frequency = action.payload.accountSet
        },
        setTrendingPeriod: (state, action) => {
            state.period = action.payload
        }
    }
})

export const { setTrendingFrequency, setTrendingPeriod } = trendingSlice.actions

export default trendingSlice.reducer