import { releaseNoteContent } from "../../redux/metaDataSlice";
import { MdNewReleases } from "react-icons/md";
import * as DOMPurify from "dompurify";
import { useAppSelector } from "../../redux/hook";
import { ReportPageStyle } from "../../models/StyleModel";

const ReleaseNoteView = () => {
    const releaseNoteContent = useAppSelector((state) => state.metaData.releaseNoteContent);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.toLocaleString("default", { month: "long" })} ${date.getDate()}, ${date.getFullYear()}`;
    };

    return (
        <div  className={`${ReportPageStyle} flex flex-col w-full gap-2 min-h-screen`} >
            <div className="flex flex-col items-center">
            <span className="text-xl p-4 font-bold text-gray-700">
                What's New In SATURN
            </span>
            <span className="text-lg p-4 text-gray-600">
                Check out the newest features and enhancement we've just
                released.
            </span>
            </div>
            <div className="flex flex-col gap-4">
            {releaseNoteContent.length > 0 ?
                releaseNoteContent.map(
                (content: releaseNoteContent, index) => {
                    const cssStyling = "font-family:sans-serif;font-size:0.875rem;line-height:1.25rem;color:rgb(100,100,100)";
                    const styledSummary = `<div style=${cssStyling}>${content.summary.replace(/ac:rich-text-body/g, "div")}</div>`;
                    const cleanStyledSummary = DOMPurify.sanitize(styledSummary);
                    const releaseDate = formatDate(content.title.split(" ")[0]);

                    return (
                        <div key={index} className="flex px-4 justify-center w-full">
                            <div className="flex flex-col rounded-lg p-4 bg-white border w-full">
                                <div className="mb-5 flex flex-col gap-2">
                                    <a href={content.link} target="_blank">
                                    <div className="flex items-center">
                                        <span className="font-bold text-l text-green-500">{content.title}</span>
                                        {index === 0 && (
                                        <div className="flex items-center pl-2 gap-2">
                                            <MdNewReleases size={28} color="blue" />
                                            <span className="font-semibold text-xs text-blue-600">Newest release</span>
                                        </div>
                                        )}
                                    </div>
                                    </a>
                                    <span className="font-thin text-xs text-gray-600">Posted by: DEFEND Software Development Team | {releaseDate}</span>
                                </div>
                                <iframe width={"100%"} srcDoc={cleanStyledSummary} />
                            </div>
                        </div>
                    );
                }
                ) : (
                <div className="flex flex-col items-center gap-2 bg-white rounded-lg mb-4">
                    <span className="font-medium text-lg p-4 text-gray-600">Loading release notes content...</span>
                </div>
            )}
            </div>
        </div>
    );
};

export default ReleaseNoteView;
