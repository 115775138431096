import { createSlice } from "@reduxjs/toolkit"

export interface HealthStatus {
    api_version: string
    diagnostics: SaturnDiagnostics
}

export interface SaturnDiagnostics {
    cosmos_db: SaturnResourceDiagnostic
    blob_storage: SaturnResourceDiagnostic
    key_vault: SaturnResourceDiagnostic
    service_bus: SaturnResourceDiagnostic
    customer_diagnostics: SaturnResourceDiagnostic
}

export interface SaturnResourceDiagnostic {
    status: string
    message: string
    timestamp: string
}

export interface AuditLogData {
    id: string
    timestamp: string
    user: string
    resource: string
    resource_id: string
    payload: string
}

export interface releaseNoteContent {
    title: string
    summary: string
    link: string
    createdAt: string
    id: string
}

interface MetaDataState {
    releaseNoteContent: releaseNoteContent[]
    diagnostics: null | HealthStatus
    auditLogs: AuditLogData[]
}

const initialState : MetaDataState = {
    releaseNoteContent: [],
    diagnostics: null,
    auditLogs: []
}

export const metaDataSlice = createSlice({
    name: 'metaData',
    initialState,
    reducers: {
        setReleaseNoteContent: (state, action) => {
            state.releaseNoteContent = action.payload
        },
        setHealthStatus: (state, action) => {
            state.diagnostics = action.payload
        },
        addAuditLogs: (state, action) => {
            state.auditLogs.push(...action.payload)
        }
    }
})

export const {
    setReleaseNoteContent, setHealthStatus, addAuditLogs
} = metaDataSlice.actions

export default metaDataSlice.reducer