import FormTitle from "../utilities/FormTitle";
import FormInput from "../utilities/FormInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setTeamsWebhookIce } from "../../../redux/formDataSlice";
import JiraForm from "../utilities/JiraForm";
import SentinelForm from "../utilities/SentinelForm";
import { useEffect } from "react";
import { setFormInvalid, setFormValid } from "../../../redux/formSlice";

const FormIce = () => {
  const dispatch = useAppDispatch();
  const currentFormData = useAppSelector((state) => state.formData);

  const validateJiraRecord = () => {
    const currentJiraRecord = currentFormData.jira_record.ice_imhr;
    const currentJiraSquad = currentFormData.squad_name;
    return (
      currentJiraRecord.organisation !== "" &&
      currentJiraRecord.project !== "" &&
      currentJiraRecord.customer !== "" &&
      currentJiraSquad !== ""
    );
  };

  const validateSentinelRecord = () => {
    const currentSentinelRecords = currentFormData.sentinel_records;
    return currentSentinelRecords.every((record) => {
      return (
        record.workspace !== "" &&
        record.workspace_id !== "" &&
        record.resource_group !== "" &&
        record.subscription_id !== ""
      );
    });
  };

  const isFieldValidated = validateJiraRecord() && validateSentinelRecord();

  useEffect(() => {
    if (isFieldValidated) dispatch(setFormValid());
    else dispatch(setFormInvalid());
  }, [isFieldValidated]);

  return (
    <div>
      <FormTitle />
      <JiraForm product="ice_imhr" />
      <SentinelForm />
      <FormInput
        title="Teams Webhook (Optional)"
        name="team-webhook"
        extraStyle="font-bold"
        value={currentFormData.teams_webhook_ice}
        required={false}
        placeholder="The Customer MS Team Webhook to use as comms channel for notification..."
        onChangeAction={(event) => dispatch(setTeamsWebhookIce(event.target.value))}
      />
    </div>
  );
};

export { FormIce };
