import { useAppSelector } from "../../redux/hook";

const IOCBlacklist = () => {
    const iocBlacklist = useAppSelector((state) => state.sherlock.iocBlacklist);

    return (
        <div className="w-[100%] max-w-6xl my-5 bg-white bg-opacity-90 overflow-x-auto">
            <div className="border border-gray-300 p-1 text-center font-bold">IOC Blacklist</div>
            <table className="w-full border border-gray-300 w-auto">
                <thead>
                    <tr>
                        <th className="border border-gray-300 p-1 text-center font-bold">Indicator</th>
                        <th className="border border-gray-300 p-1 text-center font-bold">Status</th>
                        <th className="border border-gray-300 p-1 text-center font-bold">Re-enabled Status</th>
                    </tr>
                </thead>
                <tbody>
                    {iocBlacklist.map((entry, index) => {
                        return (
                            <tr key={index}>
                                <td className="p-2 border border-gray-300 h-7">{atob(entry.id)}</td>
                                <td className="p-2 border border-gray-300 h-7">{entry.processed ? "IOC Blocked" : "Pending: Awaiting Processing"}</td>
                                <td className="p-2 border border-gray-300 h-7">{entry.deleted ? "Pending Processing" : "Not Applicable"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default IOCBlacklist