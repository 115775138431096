import { createSlice } from "@reduxjs/toolkit";
import { CustomiseReportPageContent } from "../models/ReportModel";

interface CustomiseReportState {
    selectedEntryIds: string[],
    selectedEntryId: string,
    customisedReportCustomerId: string,
    customisedReportId: string,
    currentSelectedContents: CustomiseReportPageContent[],
    currentPageContent?: CustomiseReportPageContent,

    selectedContentIdInEditor: string,
    isContentsMergedToReport: boolean,
    contentsFromReportId: ""
}

const initialState: CustomiseReportState = {
    selectedEntryIds: [],
    selectedEntryId: "",
    customisedReportCustomerId: "",
    customisedReportId: "",
    currentSelectedContents: [],

    selectedContentIdInEditor: "",
    isContentsMergedToReport: false,
    contentsFromReportId: ""
}

export interface CustomisedContentAction {
    payload: {
        id: string,
        field: string,
        value: any        
    }
}

export const customiseReportSlice = createSlice({
    name: "customiseReport",
    initialState,
    reducers: {

        setSelectedEntryId: (state, action) => {
            state.selectedEntryId = action.payload
        },
        // action.payload should be selected entry Id
        updateSelectedEntries: (state, action) => {
            if (state.selectedEntryIds.includes(action.payload)) {
                state.selectedEntryIds = state.selectedEntryIds.filter((id) => id !== action.payload)
                state.currentSelectedContents = state.currentSelectedContents.filter((c) => c.contentId !== action.payload)
                state.selectedEntryId = ""
                state.currentPageContent = undefined
            } else {
                state.selectedEntryIds.push(action.payload)
                if (state.currentPageContent) {
                    state.currentSelectedContents.push(state.currentPageContent)
                }
            }
        },
        setCurrentPageContent: (state, action) => {
            state.currentPageContent = action.payload
        },
        // reducers for report editor
        setCustomisedReportCustomerId: (state, action) => {
            state.customisedReportCustomerId = action.payload
        },
        setCustomisedReportId: (state, action) => {
            state.customisedReportId = action.payload
        },
        mergeContentsToReport: (state, action) => {
            const updatedContetns = [...action.payload, ...state.currentSelectedContents]
            state.selectedEntryIds = updatedContetns.map((c) => c.contentId)
            state.currentSelectedContents = updatedContetns
        },
        setCurrentSelectedContents: (state, action) => {
            state.currentSelectedContents = action.payload
        },
        setContentsFromReportId: (state, action) => {
            state.contentsFromReportId = action.payload
        },
        updateContentInEditor: (state, action: CustomisedContentAction) => {
            const updatedSelectedContent = state.currentSelectedContents.map((content) => 
                content.contentId === action.payload.id ?
                {...content,
                    contentData: {
                        ...content.contentData,
                        [action.payload.field]: action.payload.value
                    }
                }
                :
                content
            )
            state.currentSelectedContents = updatedSelectedContent
        },
        updateSelectedContentIdInEditor: (state, action) => {
            state.selectedContentIdInEditor = action.payload
        },
        moveContent: (state, action) => {
            const index = state.currentSelectedContents.findIndex((c) => c.contentId === state.selectedContentIdInEditor)
            let updatedSelectedContent = [...state.currentSelectedContents]
            if (action.payload === "up") {
                updatedSelectedContent[index] = state.currentSelectedContents[index - 1]
                updatedSelectedContent[index - 1] = state.currentSelectedContents[index]
            } else if (action.payload === "down") {
                updatedSelectedContent[index] = state.currentSelectedContents[index + 1]
                updatedSelectedContent[index + 1] = state.currentSelectedContents[index]
            } else {
                updatedSelectedContent = updatedSelectedContent.filter((c) => c.contentId !== state.selectedContentIdInEditor)
                state.selectedEntryIds = state.selectedEntryIds.filter((id) => id !== state.selectedContentIdInEditor)
                state.selectedContentIdInEditor = ""
            }
            state.currentSelectedContents = updatedSelectedContent
        },
        setIsContentsMergedToReport: (state, action) => {
            state.isContentsMergedToReport = action.payload
        },
        revertCustomisedReportChanges: (state) => {
            state.selectedEntryId = ""
            state.selectedEntryIds = []
            state.currentSelectedContents = []
            state.isContentsMergedToReport = false
            state.contentsFromReportId = ""
            state.selectedContentIdInEditor = ""
        },
        clearCustomisedReport: (state) => {
            state.selectedEntryId = ""
            state.selectedEntryIds = []
            state.currentSelectedContents = []
            state.isContentsMergedToReport = false
            state.contentsFromReportId = ""
            state.selectedContentIdInEditor = ""
            state.customisedReportId = ""
            state.customisedReportCustomerId = ""
        }

    }
})

export const { setSelectedEntryId, updateSelectedEntries, setCurrentPageContent, setCustomisedReportId, setCustomisedReportCustomerId,
    mergeContentsToReport, setCurrentSelectedContents, setContentsFromReportId, updateContentInEditor,
    updateSelectedContentIdInEditor, moveContent, setIsContentsMergedToReport,
    revertCustomisedReportChanges, clearCustomisedReport
} = customiseReportSlice.actions

export default customiseReportSlice.reducer