export enum EditorStyle {
    NUM_INPUT = 0,
    DOUBLE_COL_INPUT = 1,
    STR_INPUT = 2,
    MULTI_LINE_STR = 3,
    NAME_AND_COUNT = 4,
    STR_INPUT_LIST = 5,
    TRIPLE_COL_INPUT = 6,
    WORKSPACE_COST_ANALYSIS = 7,
    OPEN_TICKETS = 9,
    DOCUMENT_HISTORY = 10,
    LICENSING_STATUS_INPUT = 11,
    FIREWALL_RESOURCE_AVAILABILITY = 12,
    INTERFACE_UTILIZATION = 13,
    SOFTWARE_STATUS = 14,
    SYSTEM_RESOURCES = 15,
    INCIDENT_RESOLUTION_TARGETS = 16,
    SERVICE_REQUEST_TARGETS = 17
}

// NUM_INPUT : General entry editor
// STR_INPUT : General single editor
// STR_INPUT_LIST : General list editor
// MULTI_LINE_STR : content.general.summary in IMReport - single editor
// DOUBLE_COL_INPUT : content.microsoft_sentinel_health in IMReport - general editor
// TRIPLE_COL_INPUT : content.general.optimisation_activity in IMReport - list editor

export const REPORT_ADMIN_ROLE = "ice_squad_lead"

export const SENTINEL_HEALTH_STATUS = {
    1: "Healthy",
    2: "Action Required",
    3: "Disruption"
}

export const OPTIMISATION_ACTIVITY_STATUS = {
    1: "Complete",
    2: "On-Hold",
    3: "In Progress",
    4: "Scheduled",
    5: "Not Started"
}

export const INCIDENT_RESOLUTION_TARGETS_TEMPLATE = [
    {
        "priority": "1. Critical Impact",
        "definition": "A catastrophic fault, where a minimum of 50% of Users at a particular site cannot use a particular Service.",
        "resolution_target": "4 hours (or 8 hours where fault is assigned to a third-party network operator for resolution).",
        "progress_reports": "At 1-hour intervals following our initial response."
    },
    {
        "priority": "2. Major Impact",
        "definition": "Up to 50% of Users at a particular site cannot use a particular Service.",
        "resolution_target": "8 hours (or 16 hours where fault is assigned to a third-party network operator for resolution).",
        "progress_reports": "At 2 hourly intervals following our initial response."
    },
    {
        "priority": "3. Minor Impact",
        "definition": "Repeated quality problems. Intermittent faults or degraded service. No major service impacts.",
        "resolution_target": "5 Business Days",
        "progress_reports": "At 8 hourly intervals following our initial response."
    }
]

export const SERVICE_REQUEST_TARGETS_TEMPLATE = [
    {
        "type": "Simple Service Request", 
        "response_time": "4 Hours (Business Hours only)"
    },
    {
        "type": "Complex Service Request", 
        "response_time": "2 Days (Business Hours only)"
    },
    {
        "type": "T&M Service Request", 
        "response_time": "N/A"
    }
]