import { EditorPageStyle } from "../../models/StyleModel";
import { useAppSelector } from "../../redux/hook";
import CustomisedReportContainer from "./CustomisedReportContainer";
import IMMonthlyReport from "./IMMonthlyReport";
import IMWeeklyReport from "./IMWeeklyReport";
import SCMonthlyReport from "./SCMonthlyReport";
import SherlockReport from "./SherlockReport";

const ViewBoardContainer = () => {
  const currentControlBoard = useAppSelector((state) => state.data.currentControlBoard);

  let divStyle = "w-[90%] max-w-screen-2xl mt-5 mb-5 shadow-2xl";
  let isRenderPage = false;
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  if (pathSegments.includes("renderPage")) {
    isRenderPage = true;
    divStyle = "w-[100%] max-w-screen-2xl";
  }

  return (
    <div id="ViewBoardContainer" className={divStyle}>
      {
        {
          "": (
            <div className={`${EditorPageStyle} w-full h-60 flex justify-center items-center`}>
              <h1 className="text-white text-xl font-medium w-fit px-6 py-1.5">No Report Selected</h1>
            </div>
          ),
          "ice_imhr_week":
            <IMWeeklyReport />,
          "ice_imhr_month":
            <IMMonthlyReport />,
          "secure_connect_month":
            <SCMonthlyReport />,
          "sherlock_report_month":
            <SherlockReport />,
          "sherlock_report_week":
            <SherlockReport />,
          "ice_customised_report":
            <CustomisedReportContainer isRenderPage={isRenderPage} />
        }[currentControlBoard]
      }
    </div>
  );
};

export default ViewBoardContainer;