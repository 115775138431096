import FormInput from "./FormInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  setJiraOrganisation,
  setJiraCustomer,
  setJiraProject,
  setJiraSquad,
} from "../../../redux/formDataSlice";

const JiraForm = ({ product }: { product: string }) => {
  const dispatch = useAppDispatch();
  const currentFormData = useAppSelector((state) => state.formData);
  const currentJiraSquad = currentFormData.squad_name;

  const iCELayout = "grid grid-cols-2 md:grid-cols-2 gap-4";
  const secureConnectLayout = "grid grid-cols-2 md:grid-cols-2 gap-4";

  return (
    <div>
      <label className="block text-gray-700 text-sm font-bold my-2">
        JIRA Record
      </label>
      {product === "ice_imhr" ? (
        <div className={iCELayout}>
          <JiraConfigForm product={product} />
          <div className="flex flex-col">
            <label className="block text-gray-700 text-sm my-2">Squad</label>
            <select
              id="squads"
              name="squads"
              className="border-2 border-gray-400 rounded-lg w-full p-2.5 text-sm"
              value={currentJiraSquad}
              required={true}
              onChange={(event) => dispatch(setJiraSquad(event.target.value))}
            >
              <option></option>
              <option>Squad 1</option>
              <option>Squad 2</option>
              <option>Squad 3</option>
              <option>GE Squad</option>
            </select>
          </div>
        </div>
      ) : (
        <div className={secureConnectLayout}>
          <JiraConfigForm product={product} />
        </div>
      )}
    </div>
  );
};

const JiraConfigForm = ({ product }: { product: string }) => {
  const dispatch = useAppDispatch();
  const currentFormData = useAppSelector((state) => state.formData);
  const currentJiraOrganisation =
    currentFormData.jira_record[
      product as keyof typeof currentFormData.jira_record
    ].organisation;
    const currentJiraCustomer =
    currentFormData.jira_record[
      product as keyof typeof currentFormData.jira_record
    ].customer;
  const currentJiraProject =
    currentFormData.jira_record[
      product as keyof typeof currentFormData.jira_record
    ].project;

  return (
    <>
      <div className="flex flex-col">
        <FormInput
          title="Organisation"
          name="jira-organisation"
          required={true}
          value={currentJiraOrganisation}
          extraStyle="text-sm"
          onChangeAction={(event) =>
            dispatch(
              setJiraOrganisation({
                product: product,
                organisation: event.target.value,
                customer: "",
                project: "",
              })
            )
          }
        />
      </div>
      <div className="flex flex-col">
        <FormInput
          title="Customer"
          name="jira-customer"
          required={true}
          value={currentJiraCustomer}
          extraStyle="text-sm"
          onChangeAction={(event) =>
            dispatch(
              setJiraCustomer({
                product: product,
                organisation: "",
                customer: event.target.value,
                project: "",
              })
            )
          }
        />
      </div>
      <div className="flex flex-col">
        <FormInput
          title="Project Key"
          name="jira-projecy-key"
          required={true}
          value={currentJiraProject}
          extraStyle="text-sm"
          onChangeAction={(event) =>
            dispatch(
              setJiraProject({
                product: product,
                organisation: "",
                customer: "",
                project: event.target.value,
              })
            )
          }
        />
      </div>
    </>
  );
};

export default JiraForm;
