import Theme from "../../Theme";
import { EditorPageStyle } from "../../models/StyleModel";
import { useAppSelector } from "../../redux/hook";
import ControlBoardSection from "./ControlBoardSection";

function ControlBoardContainer() {
  const currentControlBoard = useAppSelector((state) => state.data.currentControlBoard);

  return (
    <div className="w-[90%] max-w-screen-2xl mt-5 shadow-2xl">
      {
        {
          "": (
            <div className={`${EditorPageStyle} w-full flex justify-center items-center h-60`}>
              <h1 className="text-white text-xl font-medium w-fit px-6 py-1.5">No Report Selected</h1>
            </div>
          ),

          ice_imhr_week: (
            <div className={`${EditorPageStyle} w-full grid grid-cols-3 gap-2`}>
              <div className="text-white font-medium w-fit px-2 py-1.5">
                iCE IMHR Weekly Report
                <div className="rounded-md text-white font-light text-sm">Legend:</div>
                <div className={`px-4 rounded-md text-white ${Theme.Colors.Primary} font-light text-sm`}>
                  Section with populated data
                </div>
                <div className="px-4 rounded-md text-white bg-gray-500 font-light text-sm">
                  Section with default data
                </div>
                <div className="px-4 rounded-md text-white bg-orange-500 font-light text-sm">
                  Edited section
                </div>
              </div>
              <ControlBoardSection text={"Sentinel Health"} pathToSection={"content.microsoft_sentinel_health"} colSpan="col-span-2" height="h-30" />
              <ControlBoardSection text={"Alerts By Severity"} pathToSection={"content.alerts.alerts_by_severity"} />
              <ControlBoardSection text={"Alerts By Classification"} pathToSection={"content.alerts.alerts_by_classification"} />
              <ControlBoardSection text={"Tickets By Hour"} pathToSection={"content.tickets.ticket_by_hours"} />
              <ControlBoardSection text={"Activity Summary"} pathToSection={"content.activity_summary"} height="h-24" />
              <ControlBoardSection text={"Open Tickets"} pathToSection={"content.tickets.open_tickets"} colSpan={"col-span-2"} height={"h-24"} />
            </div>
          ),

          ice_imhr_month: (
            <div className={`${EditorPageStyle} w-full grid grid-cols-8 gap-2`}>
              <div className="col-span-2 text-white font-medium w-fit px-2 py-1.5">
                iCE IMHR Monthly Report
                <div className="text-white font-light text-sm">Legend:</div>
                <div className={`px-4 rounded-md text-white ${Theme.Colors.Primary} font-light text-sm`}>
                  Section with populated data
                </div>
                <div className="px-4 rounded-md text-white bg-gray-500 font-light text-sm">
                  Section with default data
                </div>
                <div className="px-4 rounded-md text-white bg-orange-500 font-light text-sm">
                  Edited section
                </div>
              </div>
              <ControlBoardSection text={"Incidents"} pathToSection={"content.events.incidents.counts"} colSpan="col-span-1" />
              <ControlBoardSection text={"Overview"} pathToSection={"content.events.incidents.overview"} colSpan="col-span-2" />
              <ControlBoardSection text={"Near Miss"} pathToSection={"content.events.near_misses.counts"} colSpan="col-span-1" />
              <ControlBoardSection text={"Overview"} pathToSection={"content.events.near_misses.overview"} colSpan="col-span-2" />

              <ControlBoardSection text={"SLA"} colSpan="col-span-2" pathToSection="content.general.sla_breach_ticket" />
              <ControlBoardSection text={"Severity"} pathToSection={"content.alerts.by_severity.current"} colSpan="col-span-2" />
              <ControlBoardSection text={"Classification"} pathToSection={"content.alerts.by_classification.current"} colSpan="col-span-2" />
              <ControlBoardSection text={"Source"} pathToSection={"content.alerts.by_source.current"} colSpan="col-span-2" />
              
              <ControlBoardSection text={"Activity Summary"} pathToSection={"content.general"} colSpan="col-span-1" />
              <ControlBoardSection text={"Recommendation"} pathToSection={"content.general.recommendations"} colSpan="col-span-2" />
              <ControlBoardSection text={"Workspace Cost Analysis"} pathToSection={"content.workspace_cost_analysis"} colSpan={"col-span-1"} />
              <ControlBoardSection text={"Optimisation Activity"} pathToSection={"content.general.optimisation_activity"} colSpan="col-span-2" />
              <ControlBoardSection text={"Summary"} pathToSection={"content.general.summary"} colSpan="col-span-2" />
            </div>
          ),

          secure_connect_month: (
            <div className={`${EditorPageStyle} w-full grid grid-cols-4 grid-rows-4 gap-2`}>
              <div className="col-span-2 text-white font-medium w-fit px-2 py-1.5">
                Secure Connect Monthly Report
                <div className="text-white font-light text-sm">Legend:</div>
                <div className={`px-4 rounded-md text-white ${Theme.Colors.Primary} font-light text-sm`}>
                  Section with populated data
                </div>
                <div className="px-4 rounded-md text-white bg-gray-500 font-light text-sm">
                  Section with default data
                </div>
                <div className="px-4 rounded-md text-white bg-orange-500 font-light text-sm">
                  Edited section
                </div>
              </div>
              <ControlBoardSection text={"Document Details"} pathToSection={"content.general.document_history"} height={"h-30"}/>
              <ControlBoardSection text={"Executive Summary"} pathToSection={"content.general.summary"} height={"h-30"}/>

              <ControlBoardSection text={"Service Requests and Incidents Summary"} pathToSection={"content.general.service_requests_and_incidents_summary"} height={"h-30"} />
              <ControlBoardSection text={"Incident Resolution Targets"} pathToSection={"content.general.incident_resolution_targets"} height={"h-30"} />
              <ControlBoardSection text={"Service Request Targets"} pathToSection={"content.general.service_request_targets"} height={"h-30"} />
              <ControlBoardSection text={"Software Status"} pathToSection={"content.logic_monitor.software_status"} height={"h-30"} />

              <ControlBoardSection text={"Licensing Status"} pathToSection={"content.logic_monitor.software_licenses"} height={"h-30"} />
              <ControlBoardSection text={"Firewall Availability Summary"} pathToSection={"content.general.firewall_availability_summary"} height={"h-30"} />
              <ControlBoardSection text={"Firewall Monitoring"} pathToSection={"content.logic_monitor.firewall_resource_availability"} height={"h-30"} />
              <ControlBoardSection text={"Interface Utilization"} pathToSection={"content.logic_monitor.interface_utilization"} height={"h-30"} />

              <ControlBoardSection text={"System Resources"} pathToSection={"content.logic_monitor.system_resources"} height={"h-30"} />
              <ControlBoardSection text={"Alignment to Industry Best Practice"} pathToSection={"content.general.best_practice"} height={"h-30"} />
              <ControlBoardSection text={"Active Service and Incident Requests"} pathToSection={"content.tickets.open_tickets"} height={"h-30"} />
            </div>
          ),
        }[currentControlBoard]
      }
    </div>
  );
}

export default ControlBoardContainer;
