import { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddIgnoredResourceForm = ({ onSubmit, closeForm, resourceName }: any) => {
  const [description, setDescription] = useState("");
  const [noExpiry, setNoExpiry] = useState(false);

  const getDefaultDate = () => {
    var d = new Date();
    d.setMonth(d.getMonth() + 3)
    return d
  }

  const [date, setDate] = useState(getDefaultDate() as Date | null);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="flex flex-col">
            <h1 className="text-lg font-bold">Ignore health checks for resource:</h1>
            <p>{resourceName}</p>
          </div>
          <button onClick={closeForm} className="p-2 text-sm bg-gray-200 rounded hover:bg-gray-300"><MdOutlineClose size={20} /></button>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(description, noExpiry ? null : date?.toISOString());
          closeForm();
        }}>
          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Reason</label>
            <input
              type="text"
              id="description"
              name="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
             <label htmlFor="expiryDatePicker" className="block text-sm font-medium text-gray-700 pt-4 pb-1">Expiry date</label>
            <span className="flex flex-row justify-between">
              <DatePicker disabled={noExpiry} id="expiryDatePicker" className="p-2 border border-gray-300 rounded-md shadow-sm" selected={date} onChange={(date) => setDate(date)} />
              <span className="p-2">
                <label htmlFor="noExpiry" className="text-sm font-medium text-gray-700 p-2">No expiry</label>
                <input
                  type="checkbox"
                  id="noExpiry"
                  name="noExpiry"
                  className="scale-125"
                  checked={noExpiry}
                  onChange={(event) => {
                    setNoExpiry(event.target.checked);
                  }}
                />
              </span>
              <span />
            </span>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Confirm</button>
        </form>
      </div>
    </div>
  );
};

export default AddIgnoredResourceForm;
