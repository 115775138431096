export interface Sighting {
    id: string;
    indicator: string;
    indicator_id: string;
    date: string;
    organisation_id: string;
    organisation_name: string;
    client_name: string;
    client_id: string;
    platform: string;
    _ts: number
}

export interface IOC {
    id: string;
    timestamp: string;
    indicator_id: string;
    threat_level_id: number;
    info: string;
    expiry_date: string;
    org_id: number;
    orgc_id: number;
    published: boolean;
    locked: boolean;
    _ts: number;
    uuid: string;
    feeds: Feed[]
}

export const SHERLOCK_ADMIN_ROLE = "sherlock_admin"

export interface Feed {
    organisation_id: string
    organisation_name: string
    expiry_date: string
    indicator_id: string
    status: number
}

export interface SherlockCustomer {
    id: string;
    name: string;
    feeds: {
        [key: string]: boolean
    }
}

export interface SherlockCounts {
    sightings_count: number;
    indicators_count: number
}

export interface SherlockMetric {
    id: string;
    daily_counts: DailyCount[]
}

export interface DailyCount {
    [key: string]: number
}

export interface IOCBlacklistEntry {
    id: string;
    processed: boolean;
    deleted: boolean
}