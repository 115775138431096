import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { useEffect } from "react";
import { getPublishedReport } from "../service/apiAccessLogic";
import { msalInstance } from "../index";
import { setCurrentControlBoard, setCurrentReport } from "../redux/dataSlice";
import ViewBoardContainer from "../components/viewBoard/ViewBoardContainer";
import PageNotFound from "./PageNotFound";

const RenderPage = () => {
  const currentReport = useAppSelector((state) => state.data.currentReport);
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const dispatch = useAppDispatch();

  // params for published reports
  const customerId = useParams().customerId;
  const reportId = useParams().reportId;

  useEffect(() => {
    if (pathSegments.includes("publishedReport")) {
      if (customerId === undefined || reportId === undefined) return;
      if (currentReport && currentReport.customer_id === customerId && currentReport.report_id === reportId) return;
      getPublishedReport(msalInstance, customerId, reportId)
        .then((response) => {
          dispatch(setCurrentReport(response.data));
          const reportType = (response.data.report_id as unknown as string).split("_").slice(0, 3).join("_");
          dispatch(setCurrentControlBoard(reportType));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  if (pathSegments.includes("publishedReport")) {
    return (
      <div className="w-full flex flex-col items-center min-h-screen bg-cover bg-gray-300">
        <ViewBoardContainer />
      </div>
    );
  } else {
    return <PageNotFound />;
  }
};

export default RenderPage;
