import { BarDatum } from "@nivo/bar";
import { IncidentEntity, ProductCategory, TrendingCountAvgData } from "./TrendingModel";
import { Serie } from "@nivo/line";
import BGFibre from "../resource/DEFEND-Main-Contact.jpg";
import BGMap from "../resource/DEFEND-Main-About.jpg";
import BGFinger from "../resource/DEFEND-Main-Home.jpg";
import BGLock from "../resource/DEFEND-Main-Services.jpg";


export interface IMReports {
    [key: string]: {
        ice_imhr_month?: {[key: string]: ReportObject};
        ice_imhr_week?: {[key: string]: ReportObject};
        secure_connect_month?: {[key: string]: ReportObject};
        sherlock_report_month?: {[key: string]: ReportObject};
        sherlock_report_week?: {[key: string]: ReportObject};
        ice_customised_report?: {[key: string]: ReportObject};
    }
}

export const ReportTypes = ["ice_imhr_month", "ice_imhr_week", "secure_connect_month", "sherlock_report_month", "sherlock_report_week", "ice_customised_report"]
export type ReportType = "ice_imhr_month" | "ice_imhr_week" | "secure_connect_month" | "sherlock_report_month" | "sherlock_report_week" | "ice_customised_report"
export type ReportValue = string | number | boolean
export type ReportSection = ReportValue | ReportObject | ReportSection[]
export interface ReportObject {[key: string]: unknown}

export interface Ticket {
    ticket_key: string;
    summary: string;
    description: string;
    issue_type: string;
    time_created: string;
    sentinel_number: string;
    status: string;
    oncall_hours: string;
    sla_breached: boolean;
    resolution: string;
}

// iCE weekly report section
export interface SentinelHealthSection {
    data_connectors: {
        info: string;
        status: number;
    };
    hunting_queries: {
        info: string;
        status: number;
    };
    playbooks: {
        info: string;
        status: number;
    };
    workbooks: {
        info: string;
        status: number;
    };
    workspace_cost_analysis: {
        info: string;
        status: number;
    }
}

export interface AlertsBySeverity {
    high: number;
    informational: number;
    low: number;
    medium: number;
}

export interface AlertsByClassification {
    benignpositive: number;
    falsepositive: number;
    truepositive: number;
    undetermined: number;
}

export interface TicketTimes {
    tickets_after: number;
    tickets_business: number;
}

export interface TicketByHours {
    [key: string]: number;
}

export interface ActivitySummary {
    pending_alerts: number;
    pending_tickets: number;
    total_alerts: number;
    total_tickets: number;
}

export type OpenTickets = Ticket[]

// iCE monthly report section
export interface Counts {
    current: number;
    previous: number;
}

export interface AlertsBySeverityInMonth {
    current: AlertsBySeverity;
    previous: AlertsBySeverity;
}

export interface AlertsByClassificationInMonth {
    current: AlertsByClassification;
    previous: AlertsByClassification;
}

export interface AlertsBySource {
    source: string;
    count: number;
}

export interface AlertsBySourceInMonth {
    current: AlertsBySource[];
    previous: AlertsBySource[];
}

export interface OptimisationActivity {
    name: string;
    status: number;
    date: string;
}

export interface WorkspaceCostAnalysis {
    [key: string]: {
        retention_cost: number;
        ingestion_cost: number;
        logic_app_cost: number;
        total_cost: number;
        cost_anomaly_detected: boolean;
        baseline_monthly_cost: number;
        workspace_name: string;
        data_ingestion: number;
        avg_cost: number;
        calc_months: number;
        pre_avg_cost: number;
        pre_total_cost: number;
        cost_two_months_ago: number;
        cost_three_months_ago: number;
    }
}
// secure connect monthly report section
export interface Content {
    title: string;
    pageNum: number;
}

export interface DocumentHistory {
    customer: string;
    month_ending: string;
    author: string;
    notes: string;
}

export interface SoftwareStatus {
    asset: string;
    software_version: string;
    end_of_life: string;
    recommended_version: string;
    ticket_number: string;
}

export interface SoftwareLicensingStatus {
    asset: string;
    license: string;
    expiry: string;
    status: string;
}

export interface FirewallResourceAvailability {
    resource_group: string;
    resource: string;
    datasource: string;
    instance: string;
    datapoint: string;
    available: number;
    exclusions: string;
}

export interface InterfaceUtilization {
    resource: string;
    interface: string;
    description: string;
    average_inbound: string;
    max_inbound: string;
    _95th_percentile_inbound: string;
    average_outbound: string;
    max_outbound: string;
    _95th_percentile_outbound: string;
}

export interface SystemResources {
    resource: string;
    instance: string;
    datapoint: string;
    min: string;
    max: string;
    average: string;
}

export interface IncidentResolutionTarget {
    priority: string;
    definition: string;
    resolution_target: string;
    progress_reports: string;
}

export interface ServiceRequestTarget {
    type: string;
    response_time: string;
}

// SHERLOCK report sections
export interface SightingsCountByDate {
    date: string;
    count: number;
}

export interface SightedIOC {
    indicator: string;
    count: number;
}

export interface CountByOrg {
    organisation_name: string;
    count: number;
}

export interface CountByThreatLevel {
    threat_level_id: string;
    count: number;
}

export interface CountByClient {
    client_name: string;
    count: number;
}


// models for customised report 
export type CustomiseReportPageContentType = "iceImhrMonthly" | "trendingBar" | "trendingLine" | "trendingTable" | "reportTitle"

export const DynamicSizedPageContentTypes = ["iceImhrMonthly", "trendingTable"]
export interface CustomiseReportPageContent {
    contentId: string;
    contentType: CustomiseReportPageContentType;
    customerId: string;
    contentData: ReportObject | BarChartContentData | LineChartContentData | TableChartContentData | TitleContentData;
}
export interface TitleContentData {
    title: string;
    subTitle: string;
    background: string;
    author: string;
    logoRequired: boolean;
}
export interface BarChartContentData {
    chartData: BarDatum[];
    dataKeys: string[];
    category: ProductCategory;
    avgData: TrendingCountAvgData[];
    summmaryRequired: boolean;
    summary: string;
}

export interface LineChartContentData {
    chartData: Serie[];
    category: ProductCategory;
    summmaryRequired: boolean;
    summary: string;
}

export interface TableChartContentData {
    chartData: IncidentEntity[];
    category: ProductCategory;
    summmaryRequired: boolean;
    summary: string;
}

export const CustomisedTitleBackground = {
    "White": "",
    "Fibre": BGFibre,
    "Map": BGMap,
    "Finger": BGFinger,
    "Lock": BGLock
}