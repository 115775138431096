import { useEffect, useState } from "react";
import { getCustomer } from "../../../service/apiAccessLogic";
import { msalInstance } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { activateFullScreenPrompt } from "../../../redux/fullScreenPromptSlice";
import { generateAllCustomerOption } from "../../../service/menuLogic";
import { setFormInvalid, setFormValid } from "../../../redux/formSlice";
import {
  removeSelectedProducts,
  resetSelectedProducts,
  setAvailableServices,
  setCustomerNameId,
  setExistingServices,
  setSelectedProducts,
} from "../../../redux/formDataSlice";
import { Products } from "../../../models/CustomerModel";

const FormUpdateCustomer = () => {
  const dispatch = useAppDispatch();
  const [customerId, setCustomerId] = useState<string>("");
  const allCustomersID = useAppSelector((state) => state.data.allCustomersID);
  const allowedServices = useAppSelector((state) => state.form.allowedServices.services);
  const currentFormData = useAppSelector((state) => state.formData);
  const currentSelectedCustomerId = currentFormData.id;
  const currentSelectedCustomerName = currentFormData.company_name;
  const currentSelectedProducts = currentFormData.selected_products;
  const currentExistingServices = currentFormData.existing_services;
  const currentAvailableServices = currentFormData.available_services;

  useEffect(() => {
    if (customerId === "") return;
    dispatch(activateFullScreenPrompt("loading"));
    getCustomer(msalInstance, customerId)
      .then((response) => {
        const existingServices = response.data[0]["customer_info"]["products"].map((service: Products) => service.name);
        dispatch(setExistingServices(existingServices));
        dispatch(setAvailableServices(
            allowedServices
              .map((service: { id: string; name: string }) => service.name)
              .filter((name: string) => !existingServices.includes(name))
          )
        );
      })
      .catch((error) => {
        console.log("Error loading customer services: ", error);
        dispatch(activateFullScreenPrompt("error"));
      })
      .finally(() => {
        dispatch(activateFullScreenPrompt("false"));
      });
  }, [customerId]);

  const isFieldValidated =
    currentSelectedCustomerId && currentSelectedProducts.length > 0;

  useEffect(() => {
    if (isFieldValidated) dispatch(setFormValid());
    else dispatch(setFormInvalid());
  }, [isFieldValidated]);

  return (
    <div>
      <label className="block text-gray-700 text-sm my-2 font-bold">Select an Existing Customer</label>
      <select
        id="customers"
        name="customers"
        className="border-2 border-gray-400 rounded-lg p-2.5 text-sm"
        value={currentSelectedCustomerName}
        required={true}
        onChange={(event) => {
          setCustomerId(event.target.value);
          dispatch(setCustomerNameId(event.target.value));
          dispatch(resetSelectedProducts());
        }}
      >
        <option></option>
        {generateAllCustomerOption(allCustomersID)}
      </select>

      {currentSelectedCustomerId && (
        <div className="my-2">
          <label className="block text-gray-700 text-sm my-2 font-bold">
            Existing Service(s) for {currentSelectedCustomerName}:
          </label>
          {currentExistingServices.length > 0 ? (
            <ul className="list-disc pl-5 space-y-1 mb-2">
              {currentExistingServices.map((service: string, index: number) => (
                <li key={index} value={service}>
                  {service}
                </li>
              ))}
            </ul>
          ) : (
            <ul className="list-disc pl-5 space-y-1 mb-2"><li>No existing services</li></ul>
          )}
        </div>
      )}

      {currentSelectedCustomerId && (
        <div className="my-2">
          <label className="block text-gray-700 text-sm my-2 font-bold">
            Available Service(s) for {currentSelectedCustomerName}:
          </label>
          {currentAvailableServices.length > 0 ? (
            <div>
              <span className="opacity-80 text-grey-100 text-sm">Select one or more of the services to add</span>
              <div className="control mt-3">
                {currentAvailableServices.map((service: string, index: number) => (
                    <div key={index} className="control">
                      <input
                        className="h-4 w-4 rounded border-gray-300 dark:focus:ring-gray-300 focus:ring-2 dark:ring-offset-gray-800"
                        type="checkbox"
                        id={service}
                        name="services"
                        value={service}
                        checked={currentSelectedProducts.includes(service)}
                        onChange={(event) =>
                          event.target.checked
                            ? dispatch(setSelectedProducts(event.target.value))
                            : dispatch(removeSelectedProducts(event.target.value))
                        }
                      />
                      <label className="font-medium text-gray-900 m-2" htmlFor={service}>{service}</label>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <div>
              <ul className="list-disc pl-5 space-y-1 mb-2"><li>No available services to add</li></ul>
              <span className="opacity-80 text-red-500">Please close the form or select a different customer to add services to</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { FormUpdateCustomer };
