import { ReportPageStyle } from "../../models/StyleModel";
import { useAppSelector } from "../../redux/hook";
import { SaturnDiagnostics } from "../../redux/metaDataSlice";

const SystemDiagnosticsView = () => {
    const diagnostics = useAppSelector((state) => state.metaData.diagnostics);

    return (
        <div className={`${ReportPageStyle} flex flex-col w-full gap-2`} >
            <div className="flex flex-col items-center">
                <span className="text-xl p-4 font-bold text-gray-700">
                    SATURN System Diagnostics
                </span>
            </div>
            {diagnostics === null ? (
                <div className="flex flex-col items-center gap-2 bg-white rounded-lg mb-4">
                    <span className="font-medium text-lg p-4 text-gray-600">Loading system diagnostics...</span>
                </div>
            ) : (
                <div className="flex flex-col items-center gap-2 bg-white rounded-lg mb-4">
                    <span className="font-medium text-lg p-4 text-gray-600">API Version: {diagnostics.api_version}</span>
                    <div className="flex flex-col">
                        <span className="font-medium text-lg p-2 text-gray-600">Resources Health Status:</span>
                        <div className="p-2">
                            <table className="border-spacing-4">
                                <thead>
                                    <tr>
                                        <th className="p-2 border border-gray-300 font-medium text-sm">Resource Name</th>
                                        <th className="p-2 border border-gray-300 font-medium text-sm">Status</th>
                                        <th className="p-2 border border-gray-300 font-medium text-sm">Time Stamp</th>
                                        <th className="p-2 border border-gray-300 font-medium text-sm">Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(diagnostics.diagnostics as SaturnDiagnostics).map(([key, value]) => {
                                        return (
                                            <tr key={key}>
                                                <td className="border border-gray-300 text-sm text-center">{key.split("_").join(" ")}</td>
                                                <td className={`border border-gray-300 text-sm text-center ${value.status === "ok" ? "text-ui-lime" : "text-ui-red"}`}>{value.status}</td>
                                                <td className="border border-gray-300 text-sm text-center">{value.timestamp}</td>
                                                <td className="pl-2 border border-gray-300 text-sm">{value.message}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SystemDiagnosticsView