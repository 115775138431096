import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { getSherlockCustomers, getSherlockMetrics } from "../service/apiAccessLogic";
import PageNotAccessable from "./PageNotAccessable";
import { msalInstance } from "../index";
import MenuBar from "../components/MenuBar";
import TopNavBar from "../components/TopNavBar";
import { setIOCBlacklist, setLastMonthIOCsRank, setLastWeekIOCsRank, setSherlockCustomers, setSherlockDailyMetrics, setSherlockTotalCounts, setSherlockWeeklyMetrics } from "../redux/sherlockSlice";
import CustomersTable from "../components/sherlock/CustomersTable";
import AdminMetrics from "../components/sherlock/AdminMetrics";
import IndicatorTogglePanel from "../components/sherlock/IndicatorTogglePanel";
import SherlockBackground from "../resource/DEFEND-Main-Services.jpg";
import Theme from "../Theme";
import { SHERLOCK_ADMIN_ROLE } from "../models/SherlockModel";
import IOCBlacklist from "../components/sherlock/IOCBlacklist";

const SherlockAdmin = () => {
    const userMode = useAppSelector((state) => state.user.userMode);
    const userRoles = useAppSelector((state) => state.user.userRoles);
    const customers = useAppSelector((state) => state.sherlock.customers);

    const dispatch = useAppDispatch();

    if (userMode !== "editor") {
        return <PageNotAccessable />
    } else {
        return (
            <div  className={`flex ${Theme.Colors.DarkWebBg}`} onLoad={() => {
                if (customers.length > 0) return;
                dispatch(activateFullScreenPrompt("loading"))
                
                getSherlockCustomers(msalInstance).then((response) => {
                    dispatch(setSherlockCustomers(response.data));
                    dispatch(activateFullScreenPrompt("false"));
                }).catch(() => {
                    dispatch(activateFullScreenPrompt("error"));
                })

                getSherlockMetrics(msalInstance, "total_counts").then((response) => {
                    dispatch(setSherlockTotalCounts(response.data));
                }).catch((error) => {console.error("Error fetching total counts", error)})

                getSherlockMetrics(msalInstance, "daily_metrics").then((response) => {
                    dispatch(setSherlockDailyMetrics(response.data));
                }).catch((error) => {console.error("Error fetching daily metrics", error)})

                getSherlockMetrics(msalInstance, "weekly_metrics").then((response) => {
                    dispatch(setSherlockWeeklyMetrics(response.data));
                }).catch((error) => {console.error("Error fetching weekly metrics", error)})

                getSherlockMetrics(msalInstance, "iocs_ranks").then((response) => {
                    dispatch(setLastWeekIOCsRank(response.data.last_week));
                    dispatch(setLastMonthIOCsRank(response.data.last_month));
                }).catch((error) => {console.error("Error fetching IOCs rank metrics", error)})

                getSherlockMetrics(msalInstance, "iocblacklist").then((response) => {
                    dispatch(setIOCBlacklist(response.data));
                }).catch((error) => {console.error("Error fetching IOC blacklist", error)})
            }}>
                <MenuBar onPage="sherlockAdmin" />
                <div className="w-full flex flex-col items-center min-h-screen pl-44 bg-cover" style={{ backgroundImage: `url(${SherlockBackground})`}} >
                    <TopNavBar />
                    <AdminMetrics />
                    <CustomersTable />
                    <IOCBlacklist />
                    {
                        userRoles.includes(SHERLOCK_ADMIN_ROLE) 
                        &&
                        <IndicatorTogglePanel />
                    }
                </div>
            </div>
        )
    }
}

export default SherlockAdmin