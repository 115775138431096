import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { addIgnoredResource, removeIgnoredResource, setIgnoredResourceApproved } from "../../redux/customerSlice";
import { approveIgnoredResource, removeResourceFromIgnoreList } from "../../service/apiAccessLogic";
import { msalInstance } from "../..";
import ConfirmActionModal from "../forms/ignoreList/ConfirmActionModal";
import { REPORT_ADMIN_ROLE } from "../../models/EditorModel";

const PendingIgnoreComponent = ({ ignoredResource, customerId, onClose, requireConfirmation = true, message = undefined }: any) => {        
  const dispatch = useAppDispatch();
  const userRoles = useAppSelector((state) => state.user.userRoles);
  
  const [confirmingIgnore, setConfirmingIgnore] = useState(false);
  const [rejectingIgnore, setRejectingIgnore] = useState(false);


  const ignoredResourcesAll = useAppSelector((state) => state.customer.healthcheck_info.ignoredResources);

  const unignore_entry = () => {
      const resourceToRemove = ignoredResourcesAll.find((r) => r.resource_name === ignoredResource.resource_name && r.resource_type === ignoredResource.resource_type);
      dispatch(removeIgnoredResource(resourceToRemove));
      
      removeResourceFromIgnoreList(msalInstance, customerId, ignoredResource.resource_name, ignoredResource.resource_type)
        .then((response) => {
          console.log("Successfully removed entry from ignore list: ", ignoredResource.resource_name)
        }).catch(() => {
          console.log("Failed to remove entry from ignore list: ", ignoredResource.resource_name)
        });
    };

  const approveIgnore = () => {
      if (onClose) {
        onClose();
      }
      dispatch(setIgnoredResourceApproved({entry: ignoredResource, approve: true}));
      approveIgnoredResource(msalInstance, customerId, ignoredResource.resource_name, ignoredResource.resource_type)
        .then((response) => {
          console.log("Successfully approved ignored resource entry: ", ignoredResource.resource_name);
          dispatch(removeIgnoredResource(ignoredResource));
          dispatch(addIgnoredResource(response.data));
        }).catch(() => {
          dispatch(setIgnoredResourceApproved({entry: ignoredResource, approve: false}));
        });
    }

    const rejectIgnore = () => {
      if (onClose) {
        onClose();
      } 
      unignore_entry();
    }

  const defaultMessage = `${ignoredResource?.username ?? "A user"} has requested this resource is ignored:`
  
  return (
    <div className="flex flex-col bg-white shadow-md rounded-lg p-4 text-gray-800">
        {confirmingIgnore && <ConfirmActionModal title="Approve ignoring resource" description={"This resource will continue to be monitored but will not appear in summaries or generate notifications."} closeForm={() => setConfirmingIgnore(false)} onSubmit={() => approveIgnore()} />}
        {rejectingIgnore && <ConfirmActionModal title="Reject ignoring resource" description={"Rejecting this request means this resource will continue to appear in summaries and generate notifications for failures."} closeForm={() => setRejectingIgnore(false)} onSubmit={() => rejectIgnore()} />}
        <span className="text-sm text-gray-700 font-semibold">
          {message ?? defaultMessage}
        </span>
        <span className="text-sm text-gray-700 pb-2">
          {ignoredResource?.resource_name}
        </span>
        <span className="text-sm text-gray-600 pt-2">
          Reason: {ignoredResource?.description}
        </span>
        <span className="text-sm text-gray-600 pb-2">
          Expiry: {ignoredResource?.expiry ? new Date(ignoredResource.expiry).toLocaleDateString() : "Never"}
        </span>
        {userRoles.includes(REPORT_ADMIN_ROLE) &&
          <div className="flex mt-4 space-x-2">
            <button 
                className="flex-1 text-white bg-blue-500 hover:bg-blue-700 rounded-lg py-2 px-4 transition duration-300 ease-in-out"
                onClick={() => {
                  if (requireConfirmation) {
                    setConfirmingIgnore(true)
                  } else {
                    approveIgnore();
                  }
                }}
            >
              Approve
            </button>
            <button 
              className="flex-1 text-white bg-red-400 hover:bg-red-600 rounded-lg py-2 px-4 transition duration-300 ease-in-out"
              onClick={() => {
                if (requireConfirmation) {
                  setRejectingIgnore(true)
                } else {
                  rejectIgnore();
                }
              }}
            >
              Reject
            </button>
          </div>
        }
    </div>
  );
};

export default PendingIgnoreComponent;