import { BarDatum, ComputedBarDatum, ResponsiveBar } from "@nivo/bar";
import { ProductCategory, TrendingCountAvgData } from "../../models/TrendingModel";
import { TRENDING_COLOR_SCHEME } from "../../models/TrendingModel";
import {ScaleLinear} from "d3-scale";
import { hsl } from "d3-color";

export interface TrendingBarChartColorScheme {
    [key: string]: string
}

export interface TrendingAvgLineLable {
    date: string
    x: number
    y: number
    items: {
        [key: string] : number
    }
}
const TrendingBarChart = ({ data, category, dataKeys, avgData }: { data: BarDatum[], category: ProductCategory, dataKeys: string[], avgData?: TrendingCountAvgData[] }) => {
    let rightMargin: number = 140
    let colorScheme: TrendingBarChartColorScheme = {}
    let useColorScheme: boolean = false

    const lables: TrendingAvgLineLable[] = [] 

    // when there is color scheme setting for category, use setting in scheme
    if (Object.keys(TRENDING_COLOR_SCHEME).includes(category)) {
        useColorScheme = true
        colorScheme = TRENDING_COLOR_SCHEME[category]
    }

    // block chart animation and interactive in render page
    let isInteractive = true
    let hasAnimation = true
    const pathSegments = window.location.pathname.split("/").filter(Boolean) 
    if (pathSegments.includes("renderPage")) {
        isInteractive = false
        hasAnimation = false
    } 

    // update right margin of the chart, leave space for chart legend 
    switch (category) {
        case "alerts_by_source":
            rightMargin = 220
            break;
        case "alerts_by_tactics":
            rightMargin = 180
            break;
        default:
            break;
    }
    
    // function to generate array of color strings for chart
    const generateColors = () => {
        return Array.from({length: dataKeys.length}, 
            (_, i) => {
                // hsl Hue is a degree on the color wheel from 0 to 360
                // the following split color wheel and get color by dataKeys length
                const hue = (i * 360) / dataKeys.length
                const color = hsl(hue, 0.5, 0.55)
                return color.toString()
            } 
        )
    }

    
    function getTotalMaxCount() {
        let maxCountInChart: number = 0
        
        for (let entry of data) {
            let flag = 0
            for (const key in entry) {
                if (key !== "date") {
                    flag += entry[key] as unknown as number
                }
            }
            maxCountInChart = flag > maxCountInChart ? flag : maxCountInChart
        }
        return maxCountInChart
    } 

    // function to generate custom average lines based on avgData
    // line color is same as color of bar with same key
    const AvgLine = ({bars, yScale}: {bars: readonly ComputedBarDatum<BarDatum>[], yScale: any}) => {
        const maxCount = getTotalMaxCount()
        
        return (
            <>
            {avgData?.map((avg, index) => {
                const points = data.map((item) => {
                    let lineValue = avg?.chartData.find((d) => d.date === item.date)?.count
                    if (typeof lineValue === "number") {

                        const bar = bars.find((bar) => bar.data.indexValue === item.date)
                        if (bar === undefined) {
                            return ""
                        }
                        if (lineValue > maxCount) {
                            const lable = lables.find((l) => l.date === item.date)
                            if (lable) {
                                lable.items[avg.chartKey] = lineValue
                            } else {
                                const l: TrendingAvgLineLable = {"date": item.date as string, "x": bar.x + bar.width / 2, "y": maxCount + 10, items: {}}
                                l.items[avg.chartKey] = lineValue
                                lables.push(l)
                            }
                            
                            lineValue = maxCount + 5
                        }

                        return `${bar.x + bar.width / 2}, ${(yScale as ScaleLinear<number, number>)(lineValue)}`
                    }
                    return ""
                    }).filter((point) => point !== "")
                
                    return (
                        <g key={index}>
                            <polyline 
                                key={index + "bg"}
                                points={points.join(" ")}
                                fill="none"
                                stroke={"white"}
                                strokeWidth={2}
                            />
                            <polyline 
                                key={index + "color"}
                                points={points.join(" ")}
                                fill="none"
                                stroke={bars.find((b) => b.data.id === avg.chartKey)?.color || "black"}
                                strokeWidth={3}
                                strokeOpacity={0.8}
                            />
                        </g>
                    )
                })
            }
            {
                lables.map((l, index) => {
                    const y = (yScale as ScaleLinear<number, number>)(l.y)
                    return (
                        <text x={l.x} y={y} textAnchor="middle" fontSize="0.6rem" key={index} >
                            {Object.entries(l.items).map(([key, value], i) => {
                                return (
                                    <tspan key={key} x={l.x} y={y + i * 10} fill={bars.find((b) => b.data.id === key)?.color || "black"} >{`${key}"s avg: ${value}`}</tspan>
                                )
                            })}
                        </text>
                    )
                })
            }
            </>
        )
    }
    
    return (
        <ResponsiveBar
            data={data}
            keys={dataKeys}
            indexBy="date"
            margin={{ top: 50, right: rightMargin, bottom: 100, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={ useColorScheme ? ({ id }) => colorScheme[id] : generateColors()}
            borderColor={{
                from: "color",
                modifiers: [
                    [
                        "darker",
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Date",
                legendPosition: "middle",
                legendOffset: 32,
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -40,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: "color",
                modifiers: [
                    [
                        "darker",
                        1.6
                    ]
                ]
            }}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            isInteractive={isInteractive}
            animate={hasAnimation}
            layers={["grid", "axes", "bars", "markers", "legends", (props) => <AvgLine {...props} />]}
        />
    )
}

export default TrendingBarChart