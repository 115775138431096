import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { useAppDispatch, useAppSelector} from "./redux/hook";
import FullScreenPrompt from "./components/FullScreenPrompt";
import PageLayout from "./pages/PageLayout";
import LoginPage from "./pages/LoginPage";
import { activateFullScreenPrompt } from "./redux/fullScreenPromptSlice";
import { getUserDetails } from "./service/apiAccessLogic";
import { IPublicClientApplication } from "@azure/msal-browser";
import { updateUser } from "./redux/userSlice";
import { useEffect } from "react";

function App({ msalInstance }: { msalInstance: IPublicClientApplication }) {
  const isPromptActive = useAppSelector((state) => state.fullScreenPrompt.isActive);
  const companyId = useAppSelector((state) => state.user.companyId);
  const sherlockId = useAppSelector((state) => state.user.sherlockId);
  const accountSet = useAppSelector((state) => state.user.accountSet);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyId !== "" && sherlockId !== "") return;
    if (msalInstance.getAllAccounts().length === 0) return;

    dispatch(activateFullScreenPrompt("loading"));

    getUserDetails(msalInstance)
      .then((response) => {
        dispatch(updateUser(response.data));
        dispatch(activateFullScreenPrompt("false"));
      })
      .catch((error) => {
        dispatch(activateFullScreenPrompt("error"));
        if (error.response && error.response.status === 401) {
          msalInstance.logoutRedirect();
        }
      });
  }, [accountSet]);

  return (
    <MsalProvider instance={msalInstance}>
      <div>
        <AuthenticatedTemplate>
          {isPromptActive ? <FullScreenPrompt /> : null}
          <BrowserRouter>
            <PageLayout />
          </BrowserRouter>
        </AuthenticatedTemplate>
        
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
      </div>
    </MsalProvider>
  );
}

export default App;