import { ResponsivePie } from "@nivo/pie";
import { PieChartData } from "./PieChartColorFromData";


const PieChartNoLegends = ({ data, dataType }: { data: PieChartData[], dataType: string }) => {
    const isAllZeros = data.every((entry) => entry.value === 0)
    let displayData = data

    if (isAllZeros) {
        displayData = [{id: `No ${dataType}`, label: `No ${dataType}`, value: 1, color: "rgb(209 213 219)"}]
    }

    return (
        <ResponsivePie
            data={displayData}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ datum: "data.color" }}
            enableArcLabels={!isAllZeros}
            arcLabel={(data) => (data.value !== 0 ? `${data.value}` : "")}
            enableArcLinkLabels={isAllZeros}
            arcLinkLabelsTextColor="#6b7280"
            arcLinkLabelsThickness={0}
            arcLinkLabelsOffset={-80}
            arcLinkLabelsTextOffset={-45}
        />
    )
}

export default PieChartNoLegends