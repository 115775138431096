import { CustomiseReportPageContent, DynamicSizedPageContentTypes } from "../../models/ReportModel";
import { useAppSelector } from "../../redux/hook";
import { getFromPath } from "../../service/dataLogic";
import CustomisedPageContent from "../viewBoard/CustomisedPageContent";
import CustomisedControlBoardSection from "./CustomisedControlBoardSection";

const CustomisedControlBoardContainer = () => {
  const currentReport = useAppSelector((state) => state.data.currentReport);
  const currentReportId = useAppSelector((state) => state.data.currentReportId);
  const currentContents = useAppSelector((state) => state.customiseReport.currentSelectedContents);
  const isContentsMergedToReport = useAppSelector((state) => state.customiseReport.isContentsMergedToReport);
  const contentsFromReportId = useAppSelector((state) => state.customiseReport.contentsFromReportId);

  return (
    <div className="w-[90%] max-w-screen-2xl mt-5 mb-[5%] shadow-2xl">
      {!isContentsMergedToReport && currentReport &&
      currentReportId.includes("ice_customised_report") &&
      currentReportId !== contentsFromReportId ? (
        <div>
          <span className="text-white">Contents in Selected Report</span>
          {getFromPath(currentReport, "content.pages") !== undefined &&
          (getFromPath(currentReport, "content.pages") as unknown as CustomiseReportPageContent[]).map((c) => {
              const height = DynamicSizedPageContentTypes.includes(c.contentType) ? "h-fit" : "h-[60vh]";
              return (
                <div key={c.contentId} className={`w-full ${height} my-2 flex flex-row items-center gap-2`}>
                  <CustomisedPageContent content={c} size={`w-[80%] h-full`} />
                <div />
                </div>
              );
            })}
        </div>
      ) : null}
      {currentContents.length > 0 ? (
        <span className="my-2 text-white">Contents Selected</span>
      ) : null}
      {currentContents.map((content) => {
        return (
          <CustomisedControlBoardSection
            key={content.contentId}
            content={content}
          />
        );
      })}
    </div>
  );
};

export default CustomisedControlBoardContainer;