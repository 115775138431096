import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HealthCheck } from "../../models/HealthCheckModel";
import { HealthCheckEntry, HealthCheckInfo, IgnoredResource } from "../../models/CustomerModel";
import { MdOutlineCheckCircleOutline, MdOutlineErrorOutline, MdOutlineHighlightOff } from "react-icons/md";
import PaginationControls from "../utility/PaginationControls";
import { useAppSelector } from "../../redux/hook";

const statusIcon = (status: string) => {
  switch (status) {
    case "Healthy":
      return <MdOutlineCheckCircleOutline className="text-green-500 mr-2" size={24} />;
    case "Unknown":
      return <MdOutlineErrorOutline className="text-yellow-500 mr-2" size={24} />;
    default:
      return <MdOutlineHighlightOff className="text-red-500 mr-2" size={24} />;
  }
};

const getComponentHealthStatus = (
  components: HealthCheck[],
  defaultStatus: string,
  ignoredResources: IgnoredResource[] = []
) => {
  if (!components || components.length === 0) {
    return defaultStatus;
  }

  const ignored = components.filter((s: HealthCheck) => 
    ignoredResources.find((r: IgnoredResource) => r.resource_name === s.ResourceName && r.resource_type === s.ResourceType)
  );

  if (components.findIndex((s: HealthCheck) => {
    return s.HealthStatus === "Unhealthy" && !ignored.find((r: HealthCheck) => r.ResourceName === s.ResourceName && r.ResourceType === s.ResourceType)
  }) !== -1) return "Unhealthy";

  if (components.findIndex((s: HealthCheck) => {
    return s.HealthStatus === "AtRisk" && !ignored.find((r: HealthCheck) => r.ResourceName === s.ResourceName && r.ResourceType === s.ResourceType)
  }) !== -1) return "AtRisk";

  if (components.every((s: HealthCheck) => {
    return s.HealthStatus === "Healthy" || ignored.find((r: HealthCheck) => r.ResourceName === s.ResourceName && r.ResourceType === s.ResourceType)
  })) return "Healthy";

  return "Unknown";
};

const hasAnyFailures = (entry: HealthCheckEntry) => {
  return entry.health_check.automation_rules.some((a) => a.HealthStatus === "Unhealthy")
    || entry.health_check.logic_apps.some((a) => a.HealthStatus === "Unhealthy")
    || entry.health_check.data_connector_failures.some((a) => a.HealthStatus === "Unhealthy")
    || entry.health_check.data_connector_zero_logs.some((a) => a.HealthStatus === "Unhealthy");
}

const getFailureCount = (components: HealthCheck[], ignoredResources: IgnoredResource[]) => {
  if (!components || components.length === 0) {
    return 0;
  }
  
  let componentGroups: {[key: string]: HealthCheck[]} = components.reduce((groups: any, component: any) => {
    if (!groups[component.ResourceName]) {
      groups[component.ResourceName] = [];
    }
    groups[component.ResourceName].push(component);
    return groups;
  }, {});

  return Object.keys(componentGroups).filter((key: string) => componentGroups[key].some((s: HealthCheck) => {
    const ignored = ignoredResources.find((r: IgnoredResource) => r.resource_name === s.ResourceName && r.resource_type === s.ResourceType);
    return !ignored && s.HealthStatus === "Unhealthy";
  })).length;
}

const getSuccessCount = (components: HealthCheck[], ignoredResources: IgnoredResource[]) => {
  if (!components || components.length === 0) {
    return 0;
  }
  let componentGroups: {[key: string]: HealthCheck[]} = components.reduce((groups: any, component: any) => {
    if (!groups[component.ResourceName]) {
      groups[component.ResourceName] = [];
    }
    groups[component.ResourceName].push(component);
    return groups;
  }, {});

  return Object.keys(componentGroups).filter((key: string) => componentGroups[key].every((s: HealthCheck) => {
    const ignored = ignoredResources.find((r: IgnoredResource) => r.resource_name === s.ResourceName && r.resource_type === s.ResourceType);
    return ignored || s.HealthStatus === "Healthy";
  })).length;
}

const HealthChecksTable = ({ data, customerId, frequency }
  : { data: HealthCheckInfo, customerId: string, frequency: string }) => {

  const [displayFailedOnly, setDisplayFailedOnly] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const ignoredResources = useAppSelector((state) => state.customer.healthcheck_info.ignoredResources).filter((r) => r.approved);

  useEffect(() => {
    setPageIndex(0);
  }, [frequency, displayFailedOnly])

  const showAutomationRules = frequency === "hourly";
  const showLogicApps = frequency === "hourly";
  const showDataConnectorFailures = frequency === "hourly";
  const showDataConnectorActivity = frequency === "hourly" || frequency === "daily";
  
  const renderTable = () => {
    const renderHealthCheckStatusRow = (entry: HealthCheckEntry, index: number) => {
      const entryTimeFormatted = (entry.time && new Date(entry.time).toLocaleString());
      const paddedTime = entryTimeFormatted ? entryTimeFormatted.padEnd(30, " ") : "";

      const automationRulesStatus = getComponentHealthStatus(entry.health_check.automation_rules, "Healthy", ignoredResources);
      const automationRuleFailureCount = getFailureCount(entry.health_check.automation_rules, ignoredResources);
      const automationRuleSuccessCount = getSuccessCount(entry.health_check.automation_rules, ignoredResources);
      const automationRuleInfoText = automationRuleFailureCount || automationRuleSuccessCount
        ? `${automationRuleSuccessCount}/${automationRuleSuccessCount + automationRuleFailureCount} healthy`
        : "No runs observed";

      const logicAppsStatus = getComponentHealthStatus(entry.health_check.logic_apps, "Healthy", ignoredResources);
      const logicAppsFailureCount = getFailureCount(entry.health_check.logic_apps, ignoredResources);
      const logicAppsSuccessCount = getSuccessCount(entry.health_check.logic_apps, ignoredResources);
      const logicAppsInfoText = logicAppsFailureCount || logicAppsSuccessCount
        ? `${logicAppsSuccessCount}/${logicAppsSuccessCount + logicAppsFailureCount} healthy`
        : "No runs observed";

      const dataConnectorFailuresStatus = getComponentHealthStatus(entry.health_check.data_connector_failures, "Unknown", ignoredResources);
      const dataConnectorFailuresCount = getFailureCount(entry.health_check.data_connector_failures, ignoredResources);
      const dataConnectorSuccessCount = getSuccessCount(entry.health_check.data_connector_failures, ignoredResources);
      const dataConnectorInfoText = dataConnectorFailuresCount || dataConnectorSuccessCount
        ? `${dataConnectorSuccessCount}/${dataConnectorSuccessCount + dataConnectorFailuresCount} healthy`
        : "No data observed";

      const dataConnectorsActivityStatus = getComponentHealthStatus(entry.health_check.data_connector_zero_logs, "Unknown", ignoredResources);
      const dataConnectorsActivityCount = getFailureCount(entry.health_check.data_connector_zero_logs, ignoredResources);
      const dataConnectorsActivitySuccessCount = getSuccessCount(entry.health_check.data_connector_zero_logs, ignoredResources);
      const dataConnectorsActivityInfoText = dataConnectorsActivityCount || dataConnectorsActivitySuccessCount
        ? `${dataConnectorsActivitySuccessCount}/${dataConnectorsActivitySuccessCount + dataConnectorsActivityCount} healthy`
        : "No data observed";

      return (
        <div key={index} className="flex flex-col space-y-2 p-2 border-b-2 rounded-md border-gray-100 cursor-pointer" style={{ minHeight: "3rem" }}
          onClick={() => navigate(`/customers/${customerId}/healthchecks/${entry.id}?freq=${frequency}`)}>
          {/* Data Row */}
          <div className="flex justify-between text-gray-800">
            <span className="flex flex-grow justify-between items-center">
              <span className="w-[20%]">{paddedTime}</span>
              {showAutomationRules && <span className="flex w-[15%]">{statusIcon(automationRulesStatus)} {automationRuleInfoText}</span>}
              {showLogicApps && <span className="flex w-[15%]">{statusIcon(logicAppsStatus)} {logicAppsInfoText}</span>}
              {showDataConnectorFailures && <span className="flex w-[15%]">{statusIcon(dataConnectorFailuresStatus)} {dataConnectorInfoText}</span>}
              {showDataConnectorActivity && <span className="flex w-[15%]">{statusIcon(dataConnectorsActivityStatus)} {dataConnectorsActivityInfoText}</span>}
              <span className="flex w-[10%] text-blue-600 hover:text-blue-700 text-sm justify-end">
                <Link to={`/customers/${customerId}/healthchecks/${entry.id}?freq=${frequency}`}>
                  View details
                </Link>
              </span>
            </span>
          </div>
        </div>
      );
    };

    let entries: HealthCheckEntry[] = [];

    switch (frequency) {
      case "hourly":
        entries = data.hourly;
        break;
      case "daily":
        entries = data.daily;
        break;
      case "weekly":
        entries = data.weekly;
        break;
    }

    const validEntries = data && entries.length > 0
      && entries.filter((e) => e && (displayFailedOnly ? hasAnyFailures(e) : true)) || [];

    const entriesToRender = validEntries.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize);

    const fillerEntries = new Array(Math.max(0, pageSize - entriesToRender.length)).fill(0);

    return (
      <div className="w-full flex-auto rounded-md bg-white p-4 space-y-2">
        {entriesToRender .map((entry, index) => renderHealthCheckStatusRow(entry, index))}
        {fillerEntries.map((_, index) => {
          return <div key={index} className="flex flex-col space-y-2 p-2 border-b-2 rounded-md border-gray-100" style={{ minHeight: "3rem" }}></div>
        })}
        <PaginationControls
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalItems={validEntries.length}
          onPageChange={setPageIndex}
          onPageSizeChange={setPageSize}
        />
      </div>
    );
  }

  return (
    <div className="w-[100%] rounded-md bg-white shadow-lg p-4 space-y-2">
      <span className="flex p-4 items-center">
        <span className="flex pr-8 items-center">
          <span className="flex pr-2">Health check type: </span>
          <select
            id="healthchecks"
            name="healthchecks"
            className="flex border-2 border-gray-400 rounded-lg p-2.5"
            value={frequency}
            onChange={(event) => navigate(`/customers/${customerId}/healthchecks?freq=${event.target.value}`)}
          >
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
          </select>
        </span>
        <span className="flex pr-8 items-center">
          <span className="flex pr-2">Display: </span>
          <select
            id="healthcheck_success_fail"
            name="healthchecks_success_fail"
            className="flex border-2 border-gray-400 rounded-lg p-2.5"
            defaultValue={displayFailedOnly ? "Failed checks only" : "All checks"}
            onChange={(event) => setDisplayFailedOnly(event.target.value === "Failed checks only")}
          >
            <option>Failed checks only</option>
            <option>All checks</option>
          </select>
        </span>
      </span>
      <div className="items-end">
        {/* Column Headers */}
        <div className="flex justify-between items-center text-gray-700 text-semibold border-b pb-1 pl-6 pr-5">
          <span className="w-[20%]">Time</span>
            {showAutomationRules && <span className="w-[15%]">Automation Rules</span>}
            {showLogicApps && <span className="w-[15%]">Logic Apps</span>}
            {showDataConnectorFailures && <span className="w-[15%]">Data Connector Failures</span>}
            {showDataConnectorActivity && <span className="w-[15%]">Data Connector Activity</span>}
          <span className="flex w-[10%] justify-end pr-2"></span>
        </div>
        {data &&
          renderTable()}
      </div>
    </div>
  );
}

export default HealthChecksTable;