import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { useEffect, useState } from "react";
import { getHealthCheckData } from "../../service/apiAccessLogic";
import { msalInstance } from "../..";
import { updateHealthCheckInfo } from "../../redux/customerSlice";
import { useSearchParams } from "react-router-dom";
import { HealthCheckEntry, HealthCheckInfo } from "../../models/CustomerModel";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import HealthChecksTable from "./HealthChecksTable";
import HealthChecksDetailTable from "./HealthCheckDetailTable";

const HealthCheckView = () => {
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const customerId = useAppSelector((state) => state.data.currentCustomerId);
  const healthchecks = useAppSelector((state) => state.customer.healthcheck_info);
  const [loadedHealthChecks, setLoadedHealthChecks] = useState(false);
  const dispatch = useAppDispatch();
  const healthCheckId = pathSegments[3];
  const [searchParams, setSearchParams] = useSearchParams();
  const healthCheckFrequency = searchParams.get("freq") ?? "hourly";
  const healthCheckType = searchParams.get("type");

  const getHealthCheck = (healthCheckInfo: HealthCheckInfo) => {
    switch (healthCheckFrequency) {
      case "hourly":
        return healthCheckInfo.hourly.find((h) => h.id === healthCheckId);
      case "daily":
        return healthCheckInfo.daily.find((h) => h.id === healthCheckId);
      case "weekly":
        return healthCheckInfo.weekly.find((h) => h.id === healthCheckId);
    }
  }

  const healthCheck = getHealthCheck(healthchecks);

  const loadHealthChecks = async () => {
    await getHealthCheckData(msalInstance, customerId as string)
      .then((response) => {
        dispatch(updateHealthCheckInfo(response.data));
      })
      .catch((error) => {
        console.log("Error loading healthcheck information: ", error);
      })
  }

  useEffect(() => {
    if (customerId && !loadedHealthChecks && (!healthchecks?.hourly || healthchecks.hourly.length === 0)) {
      setLoadedHealthChecks(true);
      loadHealthChecks();
    }
  }, [dispatch]);

  const renderHealthcheckSummary = () => {
    return <HealthChecksTable data={healthchecks} customerId={customerId} frequency={healthCheckFrequency}/>
  };
  
  const renderHealthCheckDetail = () => {
    return <HealthChecksDetailTable healthcheck={healthCheck as HealthCheckEntry} customerId={customerId} healthCheckType={healthCheckType as string} healthCheckFrequency={healthCheckFrequency}/>
  }
  
  if ((!healthchecks?.hourly || healthchecks.hourly.length === 0) && !loadedHealthChecks) {
    return <div className="flex flex-col h-full w-full items-center">
      <AiOutlineLoading3Quarters className="animate-spin h-12 w-12 text-slate-100 mb-6"/>
  </div>
  }

  return (
    <div className="flex flex-col h-full w-full items-center">
      {(healthCheckId) ? renderHealthCheckDetail() : renderHealthcheckSummary()}
    </div>
  );
}

export default HealthCheckView