import { ColumnDef, useReactTable, ColumnResizeMode, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { IncidentEntity, ProductCategory } from "../../models/TrendingModel";
import { useEffect, useState } from "react";

const defaultIncidentEntityColumns: ColumnDef<IncidentEntity>[] = [
    {
        header: "Top Entities",
        footer: props => props.column.id,
        columns: [
            {
                accessorKey: "kind",
                header: () => <span>Entity Type</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: "friendlyName",
                header: () => <span>Entity Value</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: "count",
                header: () => <span>Count</span>,
                footer: props => props.column.id
            }
        ]
    }
]

const TrendingTable = ({data, category} : {data: IncidentEntity[], category: ProductCategory}) => {
    const [columns, setColumns] = useState<ColumnDef<IncidentEntity>[]>([])
    const [columnResizeMode] = useState<ColumnResizeMode>("onChange")
    
    const table = useReactTable({
        data: data,
        columns,
        columnResizeMode,
        getCoreRowModel: getCoreRowModel()
    })



    useEffect(() => {
        switch (category) {
            case "incident_entities":
                setColumns([...defaultIncidentEntityColumns])
                break;
            default:
                break;
        }
    }, [category])

    return (
        <table 
            className="border border-gray-300 w-full"
            style={{
                width: "100%"
            }}
        >
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="w-auto h-7">
                        {headerGroup.headers.map((header) => (
                        <th
                            key={header.id}
                            colSpan= {header.colSpan}
                            className={`border border-gray-300 p-1 text-center font-bold h-7 relative`}
                            style= {{
                                width: header.getSize(),
                                whiteSpace: "nowrap"
                            }}
                            >
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            <div
                                className={`absolute right-0 top-0 h-full w-1.5 bg-gray-700 cursor-col-resize select-none ${header.column.getIsResizing() ? "bg-blue-500 opacity-100" : "opacity-0"}`}
                                onMouseDown={header.getResizeHandler()}
                                onTouchStart={header.getResizeHandler()}
                                style={{
                                transform:
                                columnResizeMode === "onEnd" &&
                                    header.column.getIsResizing() ?  
                                        `translateX(${
                                        table.getState().columnSizingInfo.deltaOffset
                                        }px)` : "",
                                }}
                            />
                        </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="w-auto h-7">
                        {row.getVisibleCells().map((cell) => (
                        <td
                            key={cell.id}
                            className="border border-gray-300 h-7"
                            style={{
                                width: cell.column.id === "friendlyName" ? table.getTotalSize()*0.8 : table.getTotalSize()*0.1,
                                padding: "0 6px",
                                whiteSpace: "normal",
                                wordBreak: "break-word"
                            }}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )

}

export default TrendingTable;