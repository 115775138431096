import React from "react";
import { MdNavigateNext } from "react-icons/md";

interface PaginationControlsProps {
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    onPageChange: (pageIndex: number) => void;
    onPageSizeChange: (pageSize: number) => void;
}

const PaginationControls = ({ pageIndex, pageSize, totalItems, onPageChange, onPageSizeChange }: PaginationControlsProps) => {
    const totalPages = Math.ceil(totalItems / pageSize);

    const handlePrevious = () => {
        if (pageIndex > 0) {
            onPageChange(pageIndex - 1);
        }
    };

    const handleNext = () => {
        if (pageIndex < totalPages - 1) {
            onPageChange(pageIndex + 1);
        }
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onPageSizeChange(Number(event.target.value));
    };

    const paginationNumbersToShow = 10;
    const paginationNumbers = getPaginationNumbers(pageIndex, Math.max(totalPages - 1, 0), paginationNumbersToShow).slice(1);
    const leftArrowStyle = pageIndex === 0 ? "text-gray-200" : "text-ui-electricGreen hover:underline hover:text-ui-neonGreen";
    const rightArrowStyle = pageIndex === totalPages - 1 ? "text-gray-200" : "text-ui-electricGreen hover:underline hover:text-ui-neonGreen";

    const firstPageStyle = pageIndex === 0 ? "text-ui-neonGreen underline" : "text-ui-electricGreen";
    const lastPageStyle = pageIndex === totalPages - 1 ? "text-ui-neonGreen underline" : "text-ui-electricGreen";

    const showLeftDots = totalPages > paginationNumbersToShow && paginationNumbers[0] !== 2;
    const showRightDots = totalPages > paginationNumbersToShow && totalPages !== paginationNumbers[paginationNumbers.length - 1] + 1;

    const firstNumberText = "1";
    const lastNumberText = totalPages.toString();

    return (
        <span className="flex justify-between items-center">
            <span className="w-[10%]"></span>
            <span className="flex justify-around justify-center py-8">
                <button className={leftArrowStyle} onClick={handlePrevious} disabled={pageIndex === 0}>
                    <MdNavigateNext className={leftArrowStyle} style={{transform: "rotate(180deg)" }} size={36} />
                </button>
                {!showLeftDots && <span className="invisible">...</span>}
                <button className={`${firstPageStyle} hover:underline hover:text-ui-neonGreen px-4`} onClick={() => onPageChange(0)}>{firstNumberText}</button>
                {showLeftDots && <span className="text-ui-electricGreen self-center">...</span>}
                {
                    paginationNumbers.map((pageNumber, index) => {
                        const selected = pageIndex === pageNumber - 1;
                        const color = selected ? "text-ui-neonGreen underline" : "text-ui-electricGreen";
                        const onClick = () => onPageChange(pageNumber - 1);
                        return (
                            <button key={`pagination_button_fj4jfs${pageNumber}`} onClick={onClick} className={`${color} hover:underline hover:text-ui-neonGreen text-l px-4`} style={{ minWidth: "3.5rem" }}>{pageNumber}</button>
                        )
                    })
                }
                {showRightDots && <span className="text-ui-electricGreen self-center">...</span>}
                {totalPages > 1 && <button className={`${lastPageStyle} hover:underline hover:text-ui-neonGreen px-4`} onClick={() => onPageChange(totalPages-1)} style={{ minWidth: "3.5rem" }}>{lastNumberText}</button>}
                {!showRightDots && <span className="invisible">...</span>}
                <button className={rightArrowStyle} onClick={handleNext} disabled={pageIndex === totalPages - 1}>
                    <MdNavigateNext size={36} />
                </button>
            </span>
            <span className={"flex items-center"}>
                <span className={"pr-4 text-ui-electricGreen"}>per page: </span>
                <select className="flex bg-white p-2 text-ui-electricGreen" value={pageSize} onChange={handlePageSizeChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
            </span>
        </span>
    );
};

function getPaginationNumbers(currentPage: number, totalPages: number, maxPageNumbersToShow = 5) {
    let startPage: number, endPage: number;
    
    if (totalPages === 0) {
        return [];
    }

    // If the total pages is less than the max display range, show all pages
    if (totalPages <= maxPageNumbersToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate the start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPageNumbersToShow / 2) - 1;
      let maxPagesAfterCurrentPage = Math.ceil(maxPageNumbersToShow / 2);
      
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // Current page near the start
        startPage = 1;
        endPage = maxPageNumbersToShow;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }
    
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map((i) => startPage + i);
  
    return pages;
  }
  

export default PaginationControls;