import { configureStore, combineReducers, PreloadedState } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import dataReducer from "./dataSlice";
import fullScreenPromptReducer from "./fullScreenPromptSlice";
import sherlockSlice from "./sherlockSlice";
import customerReducer from "./customerSlice";
import formSlice from "./formSlice";
import formDataSlice from "./formDataSlice";
import metaDataSlice from "./metaDataSlice";
import trendingSlice from "./trendingSlice";
import customiseReportSlice  from "./customiseReportSlice";

const rootReducer = combineReducers({
    user: userReducer,
    data: dataReducer,
    metaData: metaDataSlice,
    customer: customerReducer,
    sherlock: sherlockSlice,
    fullScreenPrompt: fullScreenPromptReducer,
    form: formSlice,
    formData: formDataSlice,
    trending: trendingSlice,
    customiseReport: customiseReportSlice
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
