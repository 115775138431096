import { useInfiniteQuery } from "@tanstack/react-query";
import { getTrendingData } from "../../service/apiAccessLogic";
import { useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../../redux/hook";
import { TRENDING_CHART_CONFIGS, TrendingDataModel } from "../../models/TrendingModel";
import TrendingChartContainer from "./TrendingChartContainer";
import { MdErrorOutline } from "react-icons/md";

const TrendingBoard = () => {
  const currentCustomerId = useAppSelector((state) => state.data.currentCustomerId);
  const currentCustomerName = currentCustomerId
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const frequency = useAppSelector((state) => state.trending.frequency);
  const period = useAppSelector((state) => state.trending.period);
  const pageSize = useAppSelector((state) => state.trending.pageSize);
  // TODO check all customer ids if no current customer id
  // TODO use redux customer services
  const services = ["ice_imhr"];

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["trendingData", currentCustomerId, frequency],
    queryFn: ({ pageParam }) => getTrendingData(currentCustomerId, frequency, pageParam, pageSize),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _) => lastPage.nextCursor,
    enabled: !!currentCustomerId,
  });

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }

    if (axios.isAxiosError(error)) {
      console.log(error.response?.data);
    }
  }, [isFetching, hasNextPage]);

  const AlertCard = (message: string, alertType: string, icon?: JSX.Element) => {
    const bgColor = alertType === "error" ? "bg-red-500" : "bg-yellow-500";
    const iconColor = alertType === "error" ? "text-red-500" : "text-yellow-500";
    return (
      <div className={`w-[90%] p-3 rounded-md space-y-3 ${bgColor} bg-opacity-50`} role="alert">
        <h1 className="flex font-medium leading-none items-center text-white text-xl gap-3">
          {icon ? icon : <MdErrorOutline className={`${iconColor} top-0 left-0 h-6 w-6`} />} Alert!
        </h1>
        <div className="text-base ml-9 text-white">{message}</div>
      </div>
    );
  };

  if (currentCustomerId === "") {
    return AlertCard("Please select a customer to view trending data", "warning");
  }

  if (status === "pending") {
    return (
      <div className="flex flex-col items-center gap-4 p-20">
        <div className="w-10 h-10 border-t-2 border-b-2 border-slate-300 rounded-full animate-spin"></div>
        <p className="text-lg font-semibold text-white">Loading...</p>
      </div>
    );
  }

  if (axios.isAxiosError(error)) {
    return AlertCard(`Failed to load trending data for ${currentCustomerName}: ${error.response?.data}`, "error");
  }

  if (data === undefined) {
    return AlertCard(`Trending data for ${currentCustomerName} is not available. Please check back later.`, "warning");
  }

  const selectedData: TrendingDataModel[] = data?.pages
    ? data.pages.flatMap((page) => page.data).slice(0, period)
    : [];

  if (selectedData.length === 0) {
    return AlertCard(`No trending data found for ${currentCustomerName}`, "error");
  }

  return (
    <div className="w-full max-w-screen-2xl mt-5 mb-5 shadow-2xl flex flex-col">
      {TRENDING_CHART_CONFIGS.map((config, index) => {
        if (services.includes(config.product)) {
          return (
            <TrendingChartContainer
              key={index}
              selectedData={selectedData}
              config={config}
            />
          );
        }
      })}
    </div>
  );
};

export default TrendingBoard;