import { useState, useEffect } from "react";
import { msalInstance } from "../..";
import { Link, useNavigate } from "react-router-dom";
import { HealthCheck } from "../../models/HealthCheckModel";
import { HealthCheckEntry } from "../../models/CustomerModel";
import { MdNotificationsActive, MdNotificationsOff, MdOutlineCheckCircleOutline, MdOutlineErrorOutline, MdOutlineHighlightOff } from "react-icons/md";
import PaginationControls from "../utility/PaginationControls";
import { addResourceToIgnoreList, removeResourceFromIgnoreList } from "../../service/apiAccessLogic";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { addIgnoredResource, removeIgnoredResource } from "../../redux/customerSlice";
import AddIgnoredResourceForm from "../forms/ignoreList/AddIgnoredResourceForm";
import RemoveIgnoredResourceForm from "../forms/ignoreList/RemoveIgnoredResourceForm";
import { REPORT_ADMIN_ROLE } from "../../models/EditorModel";
import PendingIgnoreComponent from "./PendingIgnoreComponent";

const statusIcon = (status: string, ignored: boolean) => {
  switch (status) {
    case "Healthy":
      return <MdOutlineCheckCircleOutline className={ignored ? "text-gray-500 mr-2" : "text-green-500 mr-2"} size={24} />;
    case "Unknown":
      return <MdOutlineErrorOutline className={ignored ? "text-gray-500 mr-2" : "text-yellow-500 mr-2"} size={24} />;
    default:
      return <MdOutlineHighlightOff className={ignored ? "text-gray-500 mr-2" : "text-red-500 mr-2"} size={24} />;
  }
};

const HealthChecksDetailTable = ({ healthcheck, customerId, healthCheckType, healthCheckFrequency }
  : { healthcheck: HealthCheckEntry, customerId: string, healthCheckType: string, healthCheckFrequency: string }) => {  

  const [openAddIgnoredResourceForm, setOpenAddIgnoredResourceForm] = useState(undefined as (HealthCheck | undefined));
  const [openRemoveIgnoredResourceForm, setOpenRemoveIgnoredResourceForm] = useState(undefined as (HealthCheck | undefined));

  const [displayFailedOnly, setDisplayFailedOnly] = useState(true);
  const [displayIgnoredResources, setDisplayIgnoredResources] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ignoredResourcesAll = useAppSelector((state) => state.customer.healthcheck_info.ignoredResources);
  const ignoredResources = ignoredResourcesAll.filter((r) => r.approved);
  const ignoredResourcesPending = ignoredResourcesAll.filter((r) => !r.approved);

  const userRoles = useAppSelector((state) => state.user.userRoles);

  const getHealthCheckEntries = (healthCheck: HealthCheckEntry) => {
    switch (healthCheckType) {
      case "automation_rules":
        return healthCheck?.health_check?.automation_rules;
      case "logic_apps":
        return healthCheck?.health_check?.logic_apps;
      case "data_connectors":
        return healthCheck?.health_check?.data_connectors;
      case "data_connector_failures":
        return healthCheck?.health_check?.data_connector_failures;
      case "data_connector_activity":
        return healthCheck?.health_check?.data_connector_zero_logs;
      case "workbooks":
        return healthCheck?.health_check?.workbooks;
      case "hunting_queries":
        return healthCheck?.health_check?.hunting_queries;
      case "open_sentinel_incidents":
        return healthCheck?.health_check?.open_sentinel_incidents;
      // If no type is specified, show everything.
      default:
        return healthCheck?.health_check?.automation_rules
          .concat(healthCheck?.health_check?.logic_apps)
          .concat(healthCheck?.health_check?.data_connectors)
          .concat(healthCheck?.health_check?.data_connector_failures)
          .concat(healthCheck?.health_check?.data_connector_zero_logs)
          .concat(healthCheck?.health_check?.workbooks)
          .concat(healthCheck?.health_check?.hunting_queries)
          .concat(healthCheck?.health_check?.open_sentinel_incidents);
    }
  }
  
  useEffect(() => {
    const failureCount = getHealthCheckEntries(healthcheck)?.filter((e) => e && e.HealthStatus === "Unhealthy")?.length;
    if (failureCount === 0) {
      setDisplayFailedOnly(false);
    }
  }, [])

  const renderTable = () => {
    const pending_ignore_shown = [] as string[];
    const renderRow = (entry: HealthCheck, index: number) => {
      const entryTimeFormatted =
        (entry.TimeGenerated && new Date(entry.TimeGenerated).toLocaleString());
      const paddedTime = entryTimeFormatted ? entryTimeFormatted.padEnd(30, " ") : "";

      const ignore_entry = (entry: HealthCheck, description: string, expiry: string) => {
        const newEntry = {
          "resource_name": entry.ResourceName,
          "resource_type": entry.ResourceType,
          "description": description,
          "expiry": expiry,
        };

        dispatch(addIgnoredResource(newEntry));
        addResourceToIgnoreList(msalInstance, customerId, JSON.stringify(newEntry))
          .then((response) => {
            console.log("Successfully ignored entry: ", entry.ResourceName);
            dispatch(removeIgnoredResource(newEntry));
            dispatch(addIgnoredResource(response.data));

          }).catch(() => {
            dispatch(removeIgnoredResource(entry));
          });
      };

      const unignore_entry = (entry: HealthCheck) => {
        const resourceToRemove = ignoredResourcesAll.find((r) => r.resource_name === entry.ResourceName && r.resource_type === entry.ResourceType);
        dispatch(removeIgnoredResource(resourceToRemove));
        
        removeResourceFromIgnoreList(msalInstance, customerId, entry.ResourceName, entry.ResourceType)
          .then(() => {
            console.log("Successfully removed entry from ignore list: ", entry.ResourceName);
          }).catch(() => {
            console.log("Failed to remove entry from ignore list: ", entry.ResourceName);
          });
      };

      const uniqueEntryKey = entry.ResourceName + entry.ResourceType;
      const ignoredResource = ignoredResourcesAll.find((r) => r.resource_name.toLowerCase() === entry.ResourceName.toLowerCase() && r.resource_type.toLowerCase() === entry.ResourceType.toLowerCase());
      const pendingIgnore = ignoredResourcesPending.find((r) => r.resource_name.toLowerCase() === entry.ResourceName.toLowerCase() && r.resource_type.toLowerCase() === entry.ResourceType.toLowerCase()) !== undefined;
      const ignored = ignoredResource !== undefined && !pendingIgnore;

      const alreadyDisplayedIgnore = pending_ignore_shown.includes(uniqueEntryKey);

      if (pendingIgnore) {
        pending_ignore_shown.push(uniqueEntryKey);
      }

      const bgColor = ignored ? "bg-gray-100" : "bg-white";
      const textColor = ignored ? "text-gray-400" : "text-gray-800";
      const borderColor = ignored || pendingIgnore ? "border-white" : "border-gray-100";
      
      const pendingComponent = ignoredResource && pendingIgnore && !alreadyDisplayedIgnore ? 
        <PendingIgnoreComponent ignoredResource={ignoredResource} customerId={customerId} /> : null;
      
      return (        
        <div key={index} className={`flex flex-col space-y-4 p-2 border-b-2 rounded-md ${borderColor} ${bgColor}`} style={{ minHeight: "3rem" }}>
          {openAddIgnoredResourceForm === entry && <AddIgnoredResourceForm resourceName={entry.ResourceName} closeForm={() => setOpenAddIgnoredResourceForm(undefined)} onSubmit={(description: string, expiry: string) => ignore_entry(entry, description, expiry)} /> }
          {openRemoveIgnoredResourceForm === entry && <RemoveIgnoredResourceForm resourceName={entry.ResourceName} description={ignoredResource?.description} ignoredUser={ignoredResource?.username} closeForm={() => setOpenRemoveIgnoredResourceForm(undefined)} onSubmit={() => unignore_entry(entry)} />}
          {/* Data Row */}
          <div className={`flex justify-between ${textColor}`}>
            <span className="flex flex-grow justify-between items-center">
              <span className="flex">{statusIcon(entry.HealthStatus ?? "Unknown", ignored)}</span>
              <span className="flex w-[20%]">{paddedTime}</span>
              <span className="flex w-[30%]">{entry.ResourceName}</span>
              <span className="flex w-[25%]">{entry.Tags?.map((t: string) => t.toLowerCase())}</span>
              <span className="flex w-[10%] text-blue-600 hover:text-blue-700 justify-end">
                {entry.ResourceUrl && <Link to={entry.ResourceUrl} target="_blank">View in Azure</Link>}
              </span>
              <span>
                {ignored || pendingIgnore
                  ? <MdNotificationsOff className="h-5 w-5 text-red-500" onClick={() => {
                    if (userRoles.includes(REPORT_ADMIN_ROLE)) {
                      setOpenRemoveIgnoredResourceForm(entry)
                    }
                  }}/>
                  : <MdNotificationsActive className="h-5 w-5 text-green-500" onClick={() => setOpenAddIgnoredResourceForm(entry)}/>}
              </span>
            </span>
          </div>
          {entry.HealthStatus !== "Healthy" && entry.Description &&
            <div className="flex">
              <div className={`flex flex-col text-sm pt-2 ${textColor}`}>
                {entry.Description.split(/\n/).filter((line, i, arr) => line && arr.indexOf(line) === i).map((line) => <span className="flex pb-2" key={line}>{line}</span>)}
              </div>
            </div>
          }
          {(ignoredResource && ignored) &&
            <div className="flex flex-row text-sm bg-white shadow-md rounded-lg p-4 w-full justify-between">
              <div>
                <div className="flex flex-col">
                  {ignoredResource.username && <span className="flex font-semibold pb-2">This resource was ignored by {ignoredResource.username}.</span>}
                  <span className="flex">Reason: {ignoredResource.description}</span>
                </div>
                </div>
                {userRoles.includes(REPORT_ADMIN_ROLE) && <div className="flex flex-col">
                  <button 
                    className="flex-1 text-white bg-blue-400 hover:bg-blue-700 rounded-lg py-2 px-4 transition duration-300 ease-in-out"
                    onClick={() => setOpenRemoveIgnoredResourceForm(entry)}
                  >
                    Enable
                  </button>
                </div>}
            </div>
          }
          {pendingIgnore && pendingComponent}
        </div>
      );
    };

    const entries = getHealthCheckEntries(healthcheck);

    const validEntries = (entries && entries.length > 0
      && entries.filter((e) => e && (displayFailedOnly ? e.HealthStatus === "Unhealthy" : true))
      .filter((e) => e && (displayIgnoredResources ? true : !ignoredResources.find((r) => r.resource_name === e.ResourceName && r.resource_type === e.ResourceType)))
    ) || [];

    // sort by time from descending
    const entriesToRender = validEntries.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize)
      .sort((a, b) => new Date(b.TimeGenerated).getTime() - new Date(a.TimeGenerated).getTime());

    const fillerEntries = new Array(Math.max(0, pageSize - entriesToRender.length)).fill(0);

    return (
      <div className="w-full flex-auto rounded-md bg-white p-4">
        {entriesToRender.map((entry, index) => renderRow(entry, index))}
        {fillerEntries.map((_, index) => {
          return <div key={index} className="flex flex-col space-y-2 p-2 border-b-2 rounded-md border-gray-100" style={{ minHeight: "3rem" }}></div>
        })}
        <PaginationControls
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalItems={validEntries.length}
          onPageChange={setPageIndex}
          onPageSizeChange={setPageSize}
        />
      </div>
    );
  }

  return (
    <div className="w-[100%] rounded-md bg-white shadow-lg p-4 space-y-2">
      <span className="flex p-4 items-center">
        <span className="flex pr-8 items-center">
          <span className="flex pr-2">Health check type: </span>
          <select
            id="healthchecks"
            name="healthchecks"
            className="flex border-2 border-gray-400 rounded-lg p-2.5"
            value={healthCheckType}
            onChange={(event) => navigate(`/customers/${customerId}/healthchecks/${healthcheck.id}?${healthCheckFrequency && 'freq=' + healthCheckFrequency + '&'}type=${event.target.value}`)}
          >
            <option>All</option>
            <option value="logic_apps">Logic apps</option>
            <option value="automation_rules">Automation rules</option>
            <option value="data_connector_failures">Data connector failures</option>
            <option value="data_connector_activity">Data connector activity</option>
          </select>
        </span>
        <span className="flex pr-8 items-center">
          <span className="flex pr-2">Display: </span>
          <select
            id="healthcheck_success_fail"
            name="healthchecks_success_fail"
            className="flex border-2 border-gray-400 rounded-lg p-2.5"
            value={displayFailedOnly ? "Failed checks only" : "All checks"}
            onChange={(event) => setDisplayFailedOnly(event.target.value === "Failed checks only")}
          >
            <option>Failed checks only</option>
            <option>All checks</option>
          </select>
        </span>
        <span className="flex pr-8 items-center">
          <span className="flex pr-2">Ignored Resources ({ignoredResourcesPending.length} pending): </span>
          <select
            id="healthcheck_show_ignored"
            name="healthchecks_hide_ignored"
            className="flex border-2 border-gray-400 rounded-lg p-2.5"
            value={displayIgnoredResources ? "Show" : "Hide"}
            onChange={(event) => setDisplayIgnoredResources(event.target.value === "Show")}
          >
            <option>Show</option>
            <option>Hide</option>
          </select>
        </span>
      </span>
      <div className="items-end">
        {/* Column Headers */}
        <div className="flex justify-between items-center text-gray-700 text-semibold border-b pb-1 pl-8 pr-5">
          <span className="flex invisible">H</span>
          <span className="flex w-[20%]">Time</span>
          <span className="flex w-[30%]">Resource Name</span>
          <span className="flex w-[25%]">Type</span>
          <span className="flex w-[10%]"></span>
        </div>
        {healthcheck &&
          renderTable()}
      </div>
    </div>
  );
}

export default HealthChecksDetailTable;