import { useAppSelector } from "../../redux/hook";
import EntryRow from "./EntryRow";
import { getFromPath } from "../../service/dataLogic";
import { EditorStyle } from "../../models/EditorModel";
import { EditorPageStyle } from "../../models/StyleModel";

/**
 * Create an editor to edit dictionay in current report specified with 'path'.
 * Dictionary can ONLY be one layer deep. (e.i. dict={a:2, b:5, c:"text here"})
 */
interface EntryEditorProp {
  editorTitle: string;
  path: string;
}

function EntryEditor({ editorTitle, path }: EntryEditorProp) {
  let currentReport = useAppSelector((state) => state.data.currentReport);

  let style = EditorStyle.NUM_INPUT;
  if (path.includes("microsoft_sentinel_health")) {
    style = EditorStyle.DOUBLE_COL_INPUT;
  } else if (path.includes("workspace_cost_analysis")) {
    style = EditorStyle.WORKSPACE_COST_ANALYSIS;
  }

  function generateRows() {
    let rows = [];
    let ignoredKey = [
      "summary",
      "optimisation_activity",
      "recommendations",
      "sla_breach_ticket",
    ];
    if (!currentReport) return;

    let section = getFromPath(currentReport, path);

    if (typeof section === "object" && section !== null && !Array.isArray(section)) {
      for (let dictKey in section) {
        // In IMHR monthly report general section, skip fields not activity summary
        if (path === "content.general" && ignoredKey.includes(dictKey))
          continue;
        rows.push(
          <EntryRow key={dictKey} path={path.concat(".", dictKey)} style={style} />
        );
      }
    }

    return rows;
  }

  return (
    <div className={EditorPageStyle}>
      <h1 className="font-medium text-white text-xl mx-2 my-3">{editorTitle}</h1>
      {generateRows()}
    </div>
  );
}

export default EntryEditor;