import { AlertsByClassification, AlertsBySeverity, AlertsBySource } from "./ReportModel";


export interface TrendingDataModel {
    id: string,
    customer_id: string,
    company_name: string,
    frequency: TrendingFrequency
    start_date: string,
    end_date: string,
    users_count: number, 
    ice_imhr: TrendingIceImhrData  
}

// iCE IMHR data type
export interface TrendingIceImhrData {
    alerts_count: number,
    alerts_ave_count: number,
    alerts_count_per_user: number,
    alerts_by_severity: AlertsBySeverity,
    alerts_by_severity_avg: AlertsBySeverity,
    alerts_by_classification: AlertsByClassification,
    alerts_by_classification_avg: AlertsByClassification,
    alerts_by_source: AlertsBySource[],
    alerts_by_source_avg: AlertsBySourceAvg,
    alerts_by_tactics: AlertsByTactics,
    alerts_by_tactics_avg: AlertsByTactics,
    incident_entities: IncidentEntity[],
    incident_entities_by_type: IncidentEntitiesByType   
}

export interface AlertsBySourceAvg {
    [key: string]: number
}

export interface AlertsByTactics {
    [key: string]: number
}

export interface IncidentEntity {
    kind: string,
    friendlyName: string,
    count: number
}

export interface IncidentEntitiesByType {
    [key: string]: number
}

export interface TrendingDataResponseModel {
    data: TrendingDataModel[],
    nextCursor: number
}

export interface TrendingPagedDataModel {
    pageParams: number[],
    pages: TrendingDataResponseModel[]
}

export type TrendingFrequency = "month" | "week"
export type TrendingProduct = keyof TrendingDataModel
export type ProductCategory = keyof TrendingDataModel[TrendingProduct]
export type TrendingChartType = "bar" | "line" | "table"

export interface TrendingChartConfig {
    product: TrendingProduct,
    category: ProductCategory,
    chartTypes: TrendingChartType[],
    avgRequired: boolean,
    avgSource?: ProductCategory
}

// interface for trending chart average count custom line
export interface TrendingCountAvgData {
    chartKey: string
    chartData: CountByDate[],
}

export interface CountByDate {
    date: string
    count: number
}

export const TRENDING_CHART_CONFIGS: TrendingChartConfig[] = [
    {
        "product": "ice_imhr",
        "category": "alerts_count" as ProductCategory,
        "chartTypes": ["line"],
        "avgRequired": false
    },
    {
        "product": "ice_imhr",
        "category": "alerts_count_per_user" as ProductCategory,
        "chartTypes": ["line"],
        "avgRequired": false
    },
    {
        "product": "ice_imhr",
        "category": "alerts_by_classification" as ProductCategory,
        "chartTypes": ["bar"],
        "avgRequired": true,
        "avgSource": "alerts_by_classification_avg" as ProductCategory
    },
    {
        "product": "ice_imhr",
        "category": "alerts_by_severity" as ProductCategory,
        "chartTypes": ["bar"],
        "avgRequired": true,
        "avgSource": "alerts_by_severity_avg" as ProductCategory      
    },
    {
        "product": "ice_imhr",
        "category": "alerts_by_source" as ProductCategory,
        "chartTypes": ["bar"],
        "avgRequired": true,
        "avgSource": "alerts_by_source_avg" as ProductCategory       
    },
    {
        "product": "ice_imhr",
        "category": "alerts_by_tactics" as ProductCategory,
        "chartTypes": ["bar"],
        "avgRequired": true,
        "avgSource": "alerts_by_tactics_avg" as ProductCategory        
    },
    {
        "product": "ice_imhr",
        "category": "incident_entities_by_type" as ProductCategory,
        "chartTypes": ["bar"],
        "avgRequired": false
    },
    {
        "product": "ice_imhr",
        "category": "incident_entities" as ProductCategory,
        "chartTypes": ["table"],
        "avgRequired": false
    }
]

export const TRENDING_COLOR_SCHEME = {
    "alerts_by_classification": {
        "benignpositive": "rgb(147 183 83)",
        "falsepositive": "rgb(249 115 22)",
        "truepositive": "rgb(239 68 68)",
        "undetermined": "rgb(107 114 128)"
    },
    "alerts_by_severity": {
        "high": "rgb(239 68 68)",
        "low": "rgb(147 183 83)",
        "medium": "rgb(249 115 22)",
        "informational": "rgb(107 114 128)"
    },
    "alerts_count_per_user": "rgb(147 183 83)"
}
