import { updateContentInEditor, updateSelectedContentIdInEditor } from "../../redux/customiseReportSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import Theme from "../../Theme";
import RoundButton from "../utility/RoundButton";
import CustomisedPageContent from "../viewBoard/CustomisedPageContent";
import { BarChartContentData, CustomisedTitleBackground, CustomiseReportPageContent, DynamicSizedPageContentTypes, LineChartContentData, TableChartContentData, TitleContentData } from "../../models/ReportModel";
import { useState } from "react";


const CustomisedControlBoardSection = ({ content }: { content: CustomiseReportPageContent }) => {
  const contentId = content.contentId;
  const dispatch = useAppDispatch();

  const [fieldEditingInTitle, setFieldEdtingInTitle] = useState("title");

  const currentCustomerId = useAppSelector((state) => state.data.currentCustomerId);
  const selectedContentId = useAppSelector((state) => state.customiseReport.selectedContentIdInEditor);

  const sectionHeight = DynamicSizedPageContentTypes.includes(content.contentType) ? "h-fit" : "h-[64vh]";
  const warningMessage = currentCustomerId === content.customerId ? "" : `Please note this chart is from ${content.customerId}`;
  const renderContentEditor = () => {
    if (content.contentType === "iceImhrMonthly") {
      return (
        <div className="w-[20%] f-full items-center">
          {warningMessage === "" ? null : (
            <span className="text-white text-sm text-center">
              {warningMessage}
            </span>
          )}
        </div>
      );
    }

    if (content.contentType === "reportTitle") {
      const bgOptions = Object.keys(CustomisedTitleBackground);
      const titleContent = content.contentData as TitleContentData;
      const logoRequired = titleContent.logoRequired;
      const background = titleContent.background;
      return (
        <div className="w-[20%] f-full flex flex-col items-center">
          <RoundButton
            text={logoRequired ? "Remove Logo" : "Add Logo"}
            colour={Theme.Colors.Primary}
            extraStyle="w-40 mt-3"
            disabled={false}
            onClickAction={() => dispatch(updateContentInEditor({id: contentId, field: "logoRequired", value: !logoRequired}))}
          />
          <select
            className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50"
            value={background}
            disabled={false}
            onChange={(event) => dispatch(updateContentInEditor({id: contentId, field: "background", value: event.target.value}))}
          >
            <option value={""} disabled={true}>-Select background-</option>
            {bgOptions.map((option) => (
              <option key={option} value={option}>{option} Background</option>
            ))}
          </select>
          <div className="flex flex-row justify-center">
            <RoundButton
              text="Title"
              colour={`${fieldEditingInTitle === "title" ? Theme.Colors.Primary : "bg-gray-500"}`}
              extraStyle="w-16"
              disabled={fieldEditingInTitle === "title"}
              onClickAction={() => setFieldEdtingInTitle("title")}
            />
            <RoundButton
              text="SubTitle"
              colour={`${fieldEditingInTitle === "subTitle" ? Theme.Colors.Primary : "bg-gray-500"}`}
              extraStyle="w-18"
              disabled={fieldEditingInTitle === "subTitle"}
              onClickAction={() => setFieldEdtingInTitle("subTitle")}
            />
            <RoundButton
              text="Author"
              colour={`${fieldEditingInTitle === "author" ? Theme.Colors.Primary : "bg-gray-500"}`}
              extraStyle="w-18"
              disabled={fieldEditingInTitle === "author"}
              onClickAction={() => setFieldEdtingInTitle("author")}
            />
          </div>
          <textarea
            className="grow basis-60 w-full my-2 shadow-inner text-ui-darkweb px-3 py-2 rounded-md"
            placeholder={`Edit ${fieldEditingInTitle}`}
            key="Title Page Text Editor"
            value={
              fieldEditingInTitle === "title"
                ? titleContent.title
                : fieldEditingInTitle === "subTitle"
                ? titleContent.subTitle
                : titleContent.author
            }
            onChange={(event) => dispatch(updateContentInEditor({id: contentId, field: fieldEditingInTitle, value: event.target.value}))
            }
          />
        </div>
      );
    }

    const contentDataToType = content.contentData as BarChartContentData | LineChartContentData | TableChartContentData;
    const summaryRequired = contentDataToType.summmaryRequired;

    return (
      <div className="w-[20%] f-full flex flex-col items-center">
        {warningMessage === "" ? null : (
          <span className="text-white text-sm text-center">
            {warningMessage}
          </span>
        )}
        <RoundButton
          text={summaryRequired ? "Remove Summary" : "Add Summary"}
          colour={Theme.Colors.Primary}
          extraStyle="w-40 mt-3"
          disabled={false}
          onClickAction={() => {
            if (summaryRequired) {
              dispatch(updateContentInEditor({id: contentId, field: "summary", value: ""}));
            }
            dispatch(updateContentInEditor({id: contentId,field: "summmaryRequired", value: !summaryRequired}));
          }}
        />
        {summaryRequired ? (
          <textarea
            className="grow basis-72 w-full my-2 shadow-inner text-ui-darkweb px-3 py-2 rounded-md"
            placeholder="Edit page summary"
            key={contentId}
            value={contentDataToType.summary as string}
            onChange={(event) => dispatch(updateContentInEditor({id: contentId, field: "summary", value: event.target.value}))}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className={`w-full ${sectionHeight} my-2 flex flex-row items-center gap-2 ${selectedContentId === contentId ? "brightness-90" : ""} hover:brightness-90`} onClick={() => dispatch(updateSelectedContentIdInEditor(contentId))}>
      <CustomisedPageContent content={content} size="w-[80%] h-full" />

      {renderContentEditor()}
    </div>
  );
};

export default CustomisedControlBoardSection;