import { createSlice } from "@reduxjs/toolkit";
import { appendToListAtPath, deleteFromPath, setToPath, getFromPath } from "../service/dataLogic";
import { IMReports, ReportObject, ReportType } from "../models/ReportModel";
import { Baseline, BaselineObjects, HistoricalBaselineIds } from "../models/BaselineModel";
import { CustomerData } from "../models/CustomerDataModel";

export type PageType = "" | "editor" | "view";
export interface ReportIdAction {
    payload: {
        id: string;
        type: PageType;
    }
}

export type SyncTimeDataType = "baseline" | "editor" | "report";
export interface SyncTimeAction {
    payload: {
        time: string;
        type: SyncTimeDataType;
    }
}

export interface AddReportsToTypeAction {
    payload: {
        valid: boolean;
        reports: ReportObject[];
    }
}

interface DataState {
    allCustomersID: string[];
    currentCustomerId: string;
    currentCustomerData: null | CustomerData;
    currentReportType: ReportType | "";
    currentReportId: string;
    invalidReports: IMReports;
    validReports: IMReports;
    currentReport: null | ReportObject;
    currentSentinelWorkspace: string;

    currentControlBoard: string;
    currentEditor: string;
    editedSections: string[];
    reportSyncTime: string;
    editorSyncTime: string;
    baselineSyncTime: string;
    preview: boolean;
    isCustomised: boolean;

    baselines: BaselineObjects;
    currentAcceptedBaseline: null | Baseline;
    historicalBaselineIds: null | HistoricalBaselineIds;
    currentHistoricalBaseline: null | Baseline;
    displayBaselineDifferences: boolean;
}

const initialState : DataState = {
    allCustomersID: [],
    currentCustomerId : "",
    currentSentinelWorkspace: "",
    currentCustomerData : null,
    currentReportType : "",
    currentReportId: "",
    invalidReports: {},
    validReports: {},
    currentReport: null,

    currentControlBoard: "",
    currentEditor: "",
    editedSections: [],
    reportSyncTime: "",
    editorSyncTime: "",
    baselineSyncTime: "",
    preview: false,
    isCustomised: false,
    
    baselines: {},
    currentAcceptedBaseline: null,
    historicalBaselineIds: null,
    currentHistoricalBaseline: null,
    displayBaselineDifferences: false
}

export const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        /*
        ##############################################
        ############# Customers #####################
        ##############################################
        */
        setAllCustomersID: (state, action) => {
            state.allCustomersID = action.payload
        },

        /*
        ##############################################
        ############# EDIT FIELD #####################
        ##############################################
        */

        //payload: newValue:any, path:string (form "attr1.attr2.attr3")
        updateData: (state,action) => {
            if (state.currentReport === null) return
            setToPath(state.currentReport,action.payload["newValue"], action.payload["path"])
        },

        addListData: (state,action) => {
            if (state.currentReport === null) return
            appendToListAtPath(state.currentReport,action.payload["newRow"], action.payload["path"])
        },

        deleteData: (state,action) => {
            if (state.currentReport === null) return
            deleteFromPath(state.currentReport,action.payload["path"])
        },
        
        /*
        ##############################################
        ######### SETTING REPORTS ####################
        ##############################################
        */
        setCurrentCustomerId: (state, action) => {
            state.currentCustomerId = action.payload
        },
        setCurrentSentinelWorkspace: (state, action) => {
            state.currentSentinelWorkspace = action.payload
        },
        setCurrentCustomerData: (state, action) => {
            state.currentCustomerData = action.payload
        },
        setCurrentReportType: (state, action) => {
            state.currentReportType = action.payload
        },
        addReportsToType: (state, action: AddReportsToTypeAction) => {
            if (state.currentReportType === "") {
                return
            }
            
            let reportsObj: {[key: string]: ReportObject} = {}
            action.payload.reports.forEach((report) => {
                reportsObj[report["report_id"] as string] = report
            })
            if (action.payload.valid) {
                if (!state.validReports[state.currentCustomerId].hasOwnProperty(state.currentReportType)) return 
                state.validReports[state.currentCustomerId][state.currentReportType] = {
                    ...state.validReports[state.currentCustomerId][state.currentReportType],
                    ...reportsObj
                }
            } else {              
                if (!state.invalidReports[state.currentCustomerId].hasOwnProperty(state.currentReportType)) return
                state.invalidReports[state.currentCustomerId][state.currentReportType] = {
                    ...state.invalidReports[state.currentCustomerId][state.currentReportType],
                    ...reportsObj
                }
            }
        },
        setCurrentReportId: (state, action: ReportIdAction) => {
            state.currentReportId = action.payload.id
            if (state.currentReportType === "") {
                state.currentReport = null
                return
            }

            try {
                if (action.payload.type === "editor") {
                    if (!state.invalidReports[state.currentCustomerId].hasOwnProperty(state.currentReportType)) return
                    state.currentReport = state.invalidReports[state.currentCustomerId][state.currentReportType]![state.currentReportId]
                } else if (action.payload.type === "view") {
                    if (!state.validReports[state.currentCustomerId].hasOwnProperty(state.currentReportType)) return 
                    state.currentReport = state.validReports[state.currentCustomerId][state.currentReportType]![state.currentReportId]
                }
            } catch (error) {
                state.currentReport = null
            }
        },
        // function for renderPage, to set current report for other pages, pls use setCurrentReportId
        setCurrentReport: (state, action) => {
            state.currentReport = action.payload
        },

        clearCurrentReport: (state) => {
            state.currentControlBoard = "";
            state.currentEditor="";
            state.editedSections = []

            state.currentReportType  = "";
            state.currentReportId = "";
            state.currentReport = null;
        },

        setInvalidReports: (state, action) => {
            state.invalidReports = action.payload
        },

        setValidReports: (state, action) => {
            state.validReports = action.payload
        },

        /*
        ##############################################
        ######### SETTING BASELINES ##################
        ##############################################
        */
        setBaselines: (state, action) => {
            state.baselines = action.payload
        },
        setHistoricalBaselineIds: (state, action) => {
            state.historicalBaselineIds = action.payload
        },
        setCurrentAcceptedBaseline: (state, action) => {
            state.currentAcceptedBaseline = action.payload
        },
        setCurrentHistoricalBaseline: (state, action) => {
            state.currentHistoricalBaseline = action.payload
        },
        /*
        ##############################################
        ####### EDITOR AND CONTROL BOARD #############
        ##############################################
        */
        setCurrentControlBoard: (state,action) => {
            state.currentControlBoard = action.payload
        },

        setCurrentEditor: (state,action) => {
            state.currentEditor = action.payload
        },

        addToEditedSections: (state,action) => {
            if (!state.editedSections.includes(action.payload)) {
                state.editedSections.push(action.payload)
            }
        },
        
        deleteFromEditedSections: (state,action) => {
            if (state.editedSections.includes(action.payload)) {
                let index = state.editedSections.indexOf(action.payload)
                if (index !== -1) {
                    state.editedSections.splice(index,1)
                }
            }
        },

        clearEditedSections: (state) => {
            state.editedSections = []
        },

        checkForEditInSection: (state,action) => {
            if (state.currentReportType === "") { return }
            if (state.currentReport === null) return
            if (!state.invalidReports[state.currentCustomerId].hasOwnProperty(state.currentReportType)) return
            if (JSON.stringify(getFromPath(state.currentReport,action.payload)) === JSON.stringify(getFromPath(state.invalidReports[state.currentCustomerId][state.currentReportType]![state.currentReportId], action.payload))) {
                dataSlice.caseReducers.deleteFromEditedSections(state, action)
            } else {
                dataSlice.caseReducers.addToEditedSections(state, action)
            }
        },
        /*
        ##############################################
        ####### EDITOR AND CONTROL BOARD #############
        ##############################################
        */
       setLastSyncTime: (state,action: SyncTimeAction) => {
            if (action.payload.type === "baseline") {
                state.baselineSyncTime = action.payload.time
            } else if (action.payload.type === "editor") {
                state.editorSyncTime = action.payload.time
            } else {
                state.reportSyncTime = action.payload.time
            }
            
       },

       setPreview: (state, action) => {
            state.preview = action.payload
       },
       setToCustomisedEditor: (state, action) => {
        state.isCustomised = action.payload
       },

       setDisplayBaselineDifferences: (state, action) => {
            state.displayBaselineDifferences = action.payload
       }
    },
})

export const {
    updateData, addListData, deleteData, setCurrentCustomerId, setCurrentCustomerData,
    setCurrentReportType, setCurrentReportId, setCurrentReport, addReportsToType,
    setBaselines, setHistoricalBaselineIds, setCurrentAcceptedBaseline, setCurrentHistoricalBaseline,
    setCurrentControlBoard, setCurrentEditor,
    addToEditedSections, deleteFromEditedSections, clearEditedSections, checkForEditInSection,
    setInvalidReports, setValidReports, clearCurrentReport, setLastSyncTime, setPreview, setToCustomisedEditor,
    setDisplayBaselineDifferences,
    setCurrentSentinelWorkspace, setAllCustomersID
} = dataSlice.actions

export default dataSlice.reducer