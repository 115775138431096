import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { SherlockCustomer } from "../../models/SherlockModel";
import { useAppSelector } from "../../redux/hook";

const columnHelper = createColumnHelper<SherlockCustomer>()

const defaultColumns: ColumnDef<SherlockCustomer>[] = [
    {
        header: "Customers",
        footer: props => props.column.id,
        columns: [
            columnHelper.group({
                id: "info",
                footer: props => props.column.id,
                columns: [
                    {
                        accessorKey: "id",
                        header: () => <span>ID</span>,
                        footer: props => props.column.id
                    },
                    {
                        accessorKey: "name",
                        header: () => <span>Name</span>,
                        footer: props => props.column.id
                    },
                ]
            }),
            columnHelper.group({
                header: "Feeds",
                footer: props => props.column.id,
                columns: [
                    columnHelper.accessor("feeds.DEFEND", {
                        header: "DEFEND",
                        footer: props => props.column.id,
                    }),
                    columnHelper.accessor("feeds.CERT NZ", {
                        header: "CERT NZ",
                        footer: props => props.column.id,
                    }),
                    columnHelper.accessor("feeds.NCSC MFN", {
                        header: "NCSC MFN",
                        footer: props => props.column.id,
                    }),
                    columnHelper.accessor("feeds.CERT NZ Project Viaduct", {
                        header: "CERT NZ Project Viaduct",
                        footer: props => props.column.id,
                    })
                ]
              })
        ]
    }
]

const CustomersTable = () => {
    const customers = useAppSelector((state) => state.sherlock.customers);

    const table = useReactTable({
        data: customers || [],
        columns: defaultColumns,
        getCoreRowModel: getCoreRowModel()
    });

    return (
        <div className="w-[90%] h-[80vh] max-w-screen-2xl mt-5 bg-white bg-opacity-90 overflow-x-auto">
            <table 
                className="border border-gray-300 w-auto"
                style={{
                    width: table.getCenterTotalSize(),
                }}
            >
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="w-auto h-7">
                    {headerGroup.headers.map((header) => (
                    <th
                        key={header.id}
                        colSpan= {header.colSpan}
                        className={`border border-gray-300 p-1 text-center font-bold h-7 relative `}
                        style= {{
                            width: header.getSize(),
                            whiteSpace: "nowrap"
                        }}
                        >
                        {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        )}
                    </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="w-auto h-7">
                    {row.getVisibleCells().map((cell) => (
                    <td
                        key={cell.id}
                        className="border border-gray-300 h-7"
                        style={{
                            width: cell.column.getSize(),
                            padding: "0 6px",
                            whiteSpace: "normal"
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                    ))}
                </tr>
            ))}
            </tbody>
            </table>
        </div>
    )
}

export default CustomersTable