import { MdOutlineClose } from "react-icons/md";

const RemoveIgnoredResourceForm = ({ resourceName, description, ignoredUser, onSubmit, closeForm }: any) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="flex flex-col">
            <h1 className="text-lg font-bold">Remove resource from ignore list</h1>
            <p className="">{resourceName}</p>
          </div>
          <button onClick={closeForm} className="p-2 text-sm bg-gray-200 rounded hover:bg-gray-300"><MdOutlineClose size={20} /></button>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
          closeForm();
        }}>
          <div className="mb-4">
            <label className="mt-1 block w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700">
              Ignored by: {ignoredUser}
            </label>
            <p className="mt-1 block w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700">
              Reason: {description}
            </p>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Confirm Removal</button>
        </form>
      </div>
    </div>
  );
};

export default RemoveIgnoredResourceForm;
