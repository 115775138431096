import EditingBox from "../utility/EditingBox";
import { useAppSelector, useAppDispatch } from "../../redux/hook";
import { getFromPath } from "../../service/dataLogic";
import { checkForEditInSection, deleteData} from "../../redux/dataSlice";
import RoundButton from "../utility/RoundButton";
import Theme from "../../Theme";
import EditingSelector from "./EditingSelector";

/**
 * Generate editor row from specified location from path. If colNum != 1 expect form:
 * {colName1:123, colName2:"abc"}
 * @param {*} path path to navigate through current report. 
 * @returns 
 */
interface ListRowProp {
    placeholder: string | {[key: string]: string};
    path: string;
    style: number;
}

function ListRow({ placeholder, path, style } : ListRowProp) {
    let currentReport = useAppSelector((state) => state.data.currentReport);
    const userRoles = useAppSelector((state) => state.user.userRoles);
    const dispatch = useAppDispatch();
    const pathToSection = path.substring(0, path.lastIndexOf("."));

    let isSingleCol = true;
    if (style !== 5) isSingleCol = false;

    function generateColumns() {
        let col = []

        if (isSingleCol && typeof placeholder === "string") {
            return <EditingBox placeholder={placeholder} style={style} path={path}/>
        } else {
            if (!currentReport) return ;

            let section = getFromPath(currentReport,path)

            if (typeof section === "object" && section !== null && !Array.isArray(section)) {
                for (const dictKey in section) {
                    if (typeof placeholder !== "object") return;
                    if (path.includes("optimisation_activity") && dictKey === "status") {
                        col.push(<EditingSelector key={dictKey} path={path.concat(".",dictKey)}/>)
                    } else {
                        col.push(<EditingBox placeholder={placeholder[dictKey]} key={dictKey} style={style} path={path.concat(".",dictKey)} />)
                    }
                    
                }
            }

            return col 
        }
    }

    return (
        <div className={`flex m-1 p-2 ${Theme.Colors.DarkWebBg} h-fit rounded-lg shadow-sm ${Theme.Colors.Shadow}`}>
            <div className={"p-1 flex-auto"}>
                {generateColumns()}
            </div>
            <div className="self-center">
                <RoundButton
                    text="DELETE"
                    colour={Theme.Colors.Error}
                    extraStyle="flex-none w-32 h-fit py-1.5"
                    disabled={userRoles.includes("read_only") || (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] === true)}
                    onClickAction={() => {
                        dispatch(deleteData({"path": path}));
                        dispatch(checkForEditInSection(pathToSection))}
                    }
                />
            </div>
            
        </div>
    )
}

export default ListRow