import { BarDatum, ResponsiveBar } from "@nivo/bar";

const DailyCountBarChart = ({ data }: { data: BarDatum[] }) => {
    return (
        <ResponsiveBar
            data={data}
            keys={[
                "count"
            ]}
            indexBy="customer"
            margin={{ top: 20, right: 50, bottom: 50, left: 80 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            borderColor={{
                from: "color",
                modifiers: [
                    [
                        "darker",
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "customers",
                legendPosition: "middle",
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "sightings count",
                legendPosition: "middle",
                legendOffset: -60
            }}
            labelSkipWidth={12}
            labelTextColor={{
                from: "color",
                modifiers: [
                    [
                        "darker",
                        1.6
                    ]
                ]
            }}
            role="application"
        />
    )
}

export default DailyCountBarChart