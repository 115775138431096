import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { setCurrentEditor } from "../../redux/dataSlice";
import { EditorCardBGStyle, ReportCardTileStyle } from "../../models/StyleModel";
import { sectionContentIsDefault } from "../../service/dataLogic";
import Theme from "../../Theme";

interface ControlBoardSectionProp {
    text: string;
    pathToSection?: string;
    colSpan?: string;
    height?: string;
}

function ControlBoardSection({ text, pathToSection = "", colSpan = "col-span-1", height = "h-36" } : ControlBoardSectionProp) {
    const dispatch = useAppDispatch();
    const editedSectionsList = useAppSelector((state) => state.data.editedSections);
    const currentReport = useAppSelector((state) => state.data.currentReport);

    let isEdited = editedSectionsList.includes(pathToSection);

    let badgeColour = sectionContentIsDefault(currentReport, pathToSection) ? "bg-gray-500" : `${Theme.Colors.Primary}`;
    if (isEdited) {
        badgeColour = "bg-orange-500"
    }

    return (
        <div className={`${colSpan} ${height} ${EditorCardBGStyle} hover:brightness-110 active:shadow-inner active:bg-opacity-90`} 
        onClick={ () => {
            dispatch(setCurrentEditor(pathToSection));
        }}>
            <h1 className={`${badgeColour} ${ReportCardTileStyle}`}>{text}</h1>
        </div>
    )
}

export default ControlBoardSection;