import { BaselineDifferenceEntry, BaselineDifferenceObj, CommonBaselineEntry, DataConnectorEntry } from "../models/BaselineModel";
import { ReportObject, ReportSection, ReportValue, SentinelHealthSection, Ticket } from "../models/ReportModel";
import { Feed } from "../models/SherlockModel";
import { useAppSelector } from "../redux/hook";

/**
 * Add attribute in obj specified by path.
 * @param {*} obj Object to work with
 * @param {*} value content to add to path. Can be objects.
 * @param {string} path string representing path to navigate through the obj. (i.e. "obj1.attr1.attr2")
 */
export function setToPath(obj: ReportObject, value: ReportValue, path: string) {
    let pathArr = path.split(".")
    var i;
    for (i = 0; i < pathArr.length - 1; i++) {
        obj = obj[pathArr[i]] as ReportObject
    }

    obj[pathArr[i]] = value
}

/**
 * Get attribute in obj specified by path.
 * @param {*} obj Object to work with
 * @param {string} path string representing path to navigate through the obj. (i.e. "obj1.attr1.attr2")
 */
export function getFromPath(obj: ReportObject, path: string) {
    let pathArr = path.split(".");
    let reportSection: ReportSection = obj
    for (let i in pathArr) {
        if (typeof reportSection !== "object") return 

        if (!Array.isArray(reportSection)) {
            reportSection = reportSection[pathArr[i]] as ReportSection          
        } else {
            let index = parseInt(pathArr[i])
            reportSection = reportSection[index]
        }

    }

    return reportSection
}

/**
 * Delete attribute in obj specified by path.
 * @param {*} obj Object to work with
 * @param {string} path string representing path to navigate through the obj. (i.e. "obj1.attr1.attr2")
 */
export function deleteFromPath(obj: ReportObject, path: string) {
    let pathArr = path.split(".");
    var i;
    for (i = 0; i < pathArr.length - 1; i++) {
        obj = obj[pathArr[i]] as ReportObject;
    }

    if (Array.isArray(obj) && !isNaN(Number(pathArr[i]))) {
        obj.splice(parseInt(pathArr[i]), 1)
    }

}

/**
 * Push listItem into the list in obj specified by path
 * @param {*} obj Object to work with
 * @param {*} newListItem 
 * @param {*} path 
 */
export function appendToListAtPath(obj: ReportObject, newListItem: ReportValue, path: string) {
    let pathArr = path.split(".")
    var i;
    for (i = 0; i < pathArr.length; i++) {
        obj = obj[pathArr[i]] as ReportObject;
    }

    if (Array.isArray(obj)) {
        obj.push(newListItem)
    }
}

export function useCurrentReportInInvalidReports() {
    const userMode = useAppSelector((state) => state.user.userMode);
    const invalidReports = useAppSelector((state) => state.data.invalidReports);
    const currentCustomerId = useAppSelector((state) => state.data.currentCustomerId);
    const currentReportType = useAppSelector((state) => state.data.currentReportType);
    const currentReportId = useAppSelector((state) => state.data.currentReportId);

    let currentReport = null;
    
    if (userMode === "read") return {};
    if (Object.keys(invalidReports).length === 0) return {};
    if ((currentCustomerId !== "") && (currentReportType !== "") && (currentReportId !== "")) {
        if (!invalidReports[currentCustomerId].hasOwnProperty(currentReportType)) return {};
        currentReport = invalidReports[currentCustomerId][currentReportType]![currentReportId]
    } else {
        return {};
    }
    return currentReport;
}

export function sectionNameToUpperCase(name: string) {
    const wordArr = name.split("_");
    let nameToUpper = "";
    for (let word of wordArr) {
        nameToUpper += word.charAt(0).toUpperCase() + word.slice(1) + " "
    }
    return nameToUpper.trim();
}

export function parseTicketsToLineChartData(tickets: Ticket[], endDateInReport: string) {
    const endDate = new Date(endDateInReport);
    let firstDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    let initData = [];

    while (firstDate.getMonth() === endDate.getMonth()) {
        let formattedDate = new Intl.DateTimeFormat("en-NZ",{
            day: "2-digit",
            month: "2-digit"
        }).format(firstDate)
        initData.push({ x: formattedDate, y: 0 })
        firstDate.setDate(firstDate.getDate() + 1)
    }

    let chartArr = [
        {
            "id": "met",
            "data": initData.map((item) => ({...item}))
        },
        {
            "id": "breached",
            "data": initData.map((item) => ({...item}))
        }
    ]

    for (let ticket of tickets) {
        const targetIndex = parseInt(ticket.time_created.slice(0,ticket.time_created.indexOf("/"))) - 1
        let flag = ticket.sla_breached
        if (typeof ticket.sla_breached === "string") flag = String(ticket.sla_breached).toLowerCase() === "true"
        if (flag) {
            chartArr[1].data[targetIndex].y += 1
        } else {
            chartArr[0].data[targetIndex].y += 1
        }
    }

    return chartArr;
}

export function countTicketsByBreachedStatus(tickets: Ticket[]) {
    let breachedCount = 0;
    let metCount = 0;
    for (let ticket of tickets) {
        let flag = ticket.sla_breached
        if (typeof ticket.sla_breached === "string") flag = String(ticket.sla_breached).toLowerCase() === "true"
        if (flag) {
            breachedCount += 1
        } else {
            metCount += 1
        }
    }

    return {"met": metCount, "breached": breachedCount};
}

export const sectionContentIsDefault = (currentReport: ReportObject | null, pathToSection: string) => {
    if (currentReport === null) return true;

    const content = getFromPath(currentReport, pathToSection);

    if (Array.isArray(content)) {
        return content.length === 0 || (content.length === 1 && content[0] === "No recommendations.");
    }

    if (typeof content === "string") {
        return content === "PLACEHOLDER" ||
            content === "No outages were detected for the current period." ||
            content === "The service improvement section of this document will advise on work required to receive this report."
    }

    if (typeof content === "number") {
        return content === 0;
    }

    if (typeof content === "object") {
        if (pathToSection === "content.microsoft_sentinel_health") {
            return !Object.values(content as unknown as SentinelHealthSection).some((value) => 
                value.info !== "" || value.status !== 0
            )
        } else if (pathToSection.includes("current")) {
            const previousPath = pathToSection.slice(0, pathToSection.indexOf("current")) + "previous"
            const previousContent = getFromPath(currentReport, previousPath)
            if (previousContent === undefined) return true
            return !Object.values(content).some((value) => 
                value !== 0
                ) && !Object.values(previousContent).some((value) => 
                value !== 0
                )
        } else {
            return !Object.values(content).some((value) => 
                value !== 0
            )
        }
    }
}

// Baseline functions

/**
 * Comare Data connectors baseline data and return difference array
 * @param {*} currentDataConnectorEntry: DataConnectorEntry[] current accepted baseline data connectors array
 * @param {*} newDataConnectorEntry: DataConnectorEntry[] new baseline data connectors array
 */

export const compareDataConnectors = (currentDataConnectorEntry: DataConnectorEntry[], newDataConnectorEntry: DataConnectorEntry[]) => {
    let res: BaselineDifferenceObj = {};

    let parentConnectorSet = new Set([...currentDataConnectorEntry, ...newDataConnectorEntry].map((entry) => entry.displayName))
    for (let parentConnector of parentConnectorSet) {
        res[parentConnector] = {
            newEntry: [],
            updatedEntry: [],
            sameEntry: [],
            deletedEntry: []
        }
    }

    for (let newEntry of newDataConnectorEntry) {
        let currentEntry = currentDataConnectorEntry.find((obj) => obj.id === newEntry.id);
        
        if (!currentEntry) {
            const typesEmpty = Object.keys(newEntry.types).length === 0;

            if (typesEmpty) {
                res[newEntry.displayName].newEntry!.push({name: newEntry.displayName, enabled: newEntry.connected});
                continue;
            }

            for (let i = 0; i < Object.keys(newEntry.types).length; i++) {
                let differenceName = Object.keys(newEntry.types)[i];
                let enableStatus = newEntry.connected && Object.values(newEntry.types)[i].enabled;
                const last_active_data = Object.values(newEntry.types)[i].latest_active_data?.Time;

                res[newEntry.displayName].newEntry!.push({ name: differenceName, enabled: enableStatus, last_active_data: last_active_data });
            }
        }
    }

    for (let currentEntry of currentDataConnectorEntry) {
        let newEntry = newDataConnectorEntry.find((obj) => obj.id === currentEntry.id);

        const typesEmpty = Object.keys(currentEntry.types).length === 0;

        if (typesEmpty) {
            if (newEntry) {
                res[currentEntry.displayName].sameEntry!.push({ name: "(id:" + currentEntry.name + ")", enabled: true });
            } else {
                res[currentEntry.displayName].newEntry!.push({ name: "(id:" + currentEntry.name + ")", enabled: true });
            }
            
            continue;
        }

        for (let i = 0; i < Object.keys(currentEntry.types).length; i++) {
            let differenceName = Object.keys(currentEntry.types)[i] + "(id:" + currentEntry.name + ")";
            let enableStatus = Object.values(currentEntry.types)[i].enabled;
            if (newEntry) {
                if (enableStatus === Object.values(newEntry.types)[i].enabled) {
                    res[currentEntry.displayName].sameEntry!.push({ name: differenceName, enabled: enableStatus });
                } else {
                    enableStatus = Object.values(newEntry.types)[i].enabled
                    res[currentEntry.displayName].updatedEntry!.push({ name: differenceName, enabled: enableStatus });
                }
            } else {
                res[currentEntry.displayName].deletedEntry!.push({ name: differenceName, enabled: enableStatus });
            }
        }
    }

    return res;
}

/**
 * Comare common baseline data and return difference array
 * @param {*} alertType: string
 * @param {*} currentCommonEntryArr: CommonBaselineEntry[] current accepted baseline data connectors array
 * @param {*} newCommonEntryArry: CommonBaselineEntry[] new baseline data connectors array
 */

export const compareCommonTypes = (alertType: string, currentCommonEntryArr: CommonBaselineEntry[], newCommonEntryArr: CommonBaselineEntry[]) => {
    let res: {[key: string]: BaselineDifferenceEntry} = {};

    let newEntryArr: CommonBaselineEntry[] = [];
    let updatedEntryArr: CommonBaselineEntry[] = [];
    let sameEntryArr: CommonBaselineEntry[] = [];
    let deletedEntryArr: CommonBaselineEntry[] = [];

    for (let newEntry of newCommonEntryArr) {
        let currentEntry = currentCommonEntryArr.find((obj) => obj.name === newEntry.name);
        if (!currentEntry) {
            newEntryArr.push(newEntry);
        }
    }

    for (let currentEntry of currentCommonEntryArr) {
        let newEntry = newCommonEntryArr.find((obj) => obj.name === currentEntry.name);

        if (newEntry) {
            if (newEntry.name === currentEntry.name && newEntry.enabled === currentEntry.enabled) {
                sameEntryArr.push(currentEntry);
            } else {
                updatedEntryArr.push(newEntry);
            }
        } else {
            deletedEntryArr.push(currentEntry);
        }
    }

    res[alertType] = {
        newEntry: newEntryArr,
        updatedEntry: updatedEntryArr,
        sameEntry: sameEntryArr,
        deletedEntry: deletedEntryArr
    }

    return res;
}

// Sherlock functions 
export const parseFeedsSource = (feeds: Feed[]) => {
    let result: string = ""
    feeds.forEach((feed) => {
        result += feed.organisation_name + "; "
    })
    return result;
}
