import { createSlice } from "@reduxjs/toolkit";

interface UserState {
    userMode: "read" | "editor";
    userRoles: string[];
    companyId: string;
    sherlockId: string;
    sherlockWebsocketMethod: string,
    accountSet: boolean
}

const initialState: UserState = {
    userMode: "read",
    userRoles: [],
    companyId: "",
    sherlockId: "",
    sherlockWebsocketMethod: "",
    accountSet: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state,action) => {
            state.userMode = action.payload.user_mode
            state.userRoles = action.payload.user_roles
            state.companyId = action.payload.user_company
            state.sherlockId = action.payload.user_sherlock
            if (action.payload.user_mode === "editor") {
                state.sherlockWebsocketMethod = "sherlock-editor"
            } else {
                state.sherlockWebsocketMethod = action.payload.user_sherlock
            }
        },
        setAccountSet: (state,action) => {
            state.accountSet = action.payload.accountSet
        }
    }
})

export const {updateUser, setAccountSet} = userSlice.actions

export default userSlice.reducer