import { ResponsiveLine, Serie } from "@nivo/line";


const TrendingLineChart = ({ data }: { data: Serie[] }) => {

    // block chart animation and interactive in render page
    let isInteractive = true;
    let hasAnimation = true;
    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    if (pathSegments.includes("renderPage")) {
        isInteractive = false
        hasAnimation = false
    } 

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 160, bottom: 100, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Date",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -50,
                legendPosition: "middle",
                truncateTickAt: 0
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            isInteractive={isInteractive}
            animate={hasAnimation}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default TrendingLineChart;