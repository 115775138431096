import { useAppSelector } from "../../redux/hook";
import ViewBoardContainer from "../viewBoard/ViewBoardContainer";

const PreviewContainer = () => {
    const previewMode = useAppSelector((state) => state.data.preview);

    if (previewMode) {
        return <ViewBoardContainer />
    } else {
        return <div></div>
    }
}

export default PreviewContainer