import { useAppSelector } from "../../redux/hook";
import ListEditor from "./ListEditor";
import EntryEditor from "./EntryEditor";
import SingleEditor from "./SingleEditor";
import { EditorPageStyle } from "../../models/StyleModel";
import ListEditorWithTemplate from "./ListEditorWithTemplate";

function EditorContainer() {
	const currentEditor = useAppSelector(state=>state.data.currentEditor)

	return (
		<div className="w-[90%] max-w-screen-2xl mt-5 mb-[5%] shadow-2xl">
			{
				{
					"": 
					<div className={`${EditorPageStyle} w-full flex justify-center items-center h-60`}>
						<h1 className="text-white text-xl font-medium w-fit px-6 py-1.5">No Section Selected</h1>
					</div>,

					/*################################
						##IMHR MONTHLY REPORT EDITORS###
						################################*/
					"content.events.incidents.counts": <EntryEditor editorTitle={"Incidents"} path={"content.events.incidents.counts"} />,
					"content.events.incidents.overview":<ListEditor editorTitle={"Incidents Overview"} path={"content.events.incidents.overview"} />,
					"content.events.near_misses.counts":<EntryEditor editorTitle={"Near Misses"} path={"content.events.near_misses.counts"} />,
					"content.events.near_misses.overview": <ListEditor editorTitle={"Near Misses Overview"} path={"content.events.near_misses.overview"} />,
					"content.general.summary": <SingleEditor editorTitle={"Summary"} path={"content.general.summary"} />,
					"content.alerts.by_severity.current": <EntryEditor editorTitle={"Alerts By Severity"} path={"content.alerts.by_severity.current"} />,
					"content.alerts.by_classification.current":<EntryEditor editorTitle={"Alerts By Classification"} path={"content.alerts.by_classification.current"} />,
					"content.alerts.by_source.current":<ListEditor editorTitle={"Alerts By Source"} path={"content.alerts.by_source.current"} />,
					"content.general.optimisation_activity" : <ListEditor editorTitle={"Optimisation Activities"} path={"content.general.optimisation_activity"} />,
					"content.general.recommendations": <ListEditor editorTitle={"Recommendations"} path={"content.general.recommendations"} />,
					"content.general.sla_breach_ticket":<SingleEditor editorTitle={"SLA Breaches"} path={"content.general.sla_breach_ticket"} />,
					"content.workspace_cost_analysis": <EntryEditor editorTitle={"Workspace Cost Analysis"} path={"content.workspace_cost_analysis"} />,
					"content.general": <EntryEditor editorTitle="Activity Summary" path={"content.general"} />,
					
					/*################################
						##IMHR WEEKLY REPORT EDITORS####
						################################*/
					"content.microsoft_sentinel_health":<EntryEditor editorTitle={"Sentinel Health"} path={"content.microsoft_sentinel_health"} />,
					"content.alerts.alerts_by_severity": <EntryEditor editorTitle={"Alerts By Severity"} path={"content.alerts.alerts_by_severity"} />,
					"content.alerts.alerts_by_classification": <EntryEditor editorTitle={"Alerts By Classification"} path={"content.alerts.alerts_by_classification"} />,
					"content.tickets.ticket_by_hours": <EntryEditor editorTitle={"Tickets By Hour"} path={"content.tickets.ticket_by_hours"} />,
					"content.activity_summary": <EntryEditor editorTitle={"Activity Summary"} path={"content.activity_summary"} />,
					"content.tickets.open_tickets": <ListEditor editorTitle={"Open Ticket Details"} path={"content.tickets.open_tickets"} />,

					/*#########################################
						##SECURE CONNECT MONTHLY REPORT EDITORS##
						#######################################*/
					"content.general.document_history": <ListEditor editorTitle={"Document History"} path={"content.general.document_history"} />,
					"content.general.service_requests_and_incidents_summary": <SingleEditor editorTitle={"Service Requests and Incidents Summary"} path={"content.general.service_requests_and_incidents_summary"} />,
					"content.general.incident_resolution_targets": <ListEditorWithTemplate editorTitle={"Incident Resolution Targets"} path={"content.general.incident_resolution_targets"} />,
					"content.general.service_request_targets": <ListEditorWithTemplate editorTitle={"Service Request Targets"} path={"content.general.service_request_targets"} />,
					"content.logic_monitor.software_status": <ListEditor editorTitle={"Software Status"} path={"content.logic_monitor.software_status"} />,
					"content.logic_monitor.software_licenses": <ListEditor editorTitle={"Licensing Status"} path={"content.logic_monitor.software_licenses"}/>,
					"content.general.firewall_availability_summary": <SingleEditor editorTitle={"Firewall Availability Summary"} path={"content.general.firewall_availability_summary"} />,
					"content.logic_monitor.firewall_resource_availability": <ListEditor editorTitle={"Firewall Resource Availability"} path={"content.logic_monitor.firewall_resource_availability"} />,
					"content.logic_monitor.interface_utilization": <ListEditor editorTitle={"Interface Utilization"} path={"content.logic_monitor.interface_utilization"} />,
					"content.logic_monitor.system_resources": <ListEditor editorTitle={"System Resources"} path={"content.logic_monitor.system_resources"} />,
					"content.general.best_practice": <SingleEditor editorTitle={"Alignment to Industry Best Practice"} path={"content.general.best_practice"} />
				}[currentEditor]
			}
		</div>
	)
}

export default EditorContainer